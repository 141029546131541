import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import { fetchItems } from '../../Common/SharedApis/Item';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import  Payment from './Payment'
import Products from './Products';
const MainPOS = () => {
 
  const navigate = useNavigate();

  return (
    <div className="main-wrapper mt-4">
      <div className="page-wrapper pos-pg-wrapper ms-0">
        <div className="content pos-design p-0">
          <div className="row align-items-start pos-wrapper">
            <Products />
            <Payment />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPOS;
