import apiBaseUrl from '../ApiBaseUrl';
var token = localStorage.getItem('authToken')?.toString();
export const TrackingOrdersByStatus = async (status: number) => {
  // Retrieve the token on each API call
  const authToken = localStorage.getItem('authToken'); 

  // Check if the token is available
  if (!authToken) {
    console.error("No authentication token found. Please log in.");
    throw new Error("No authentication token found. Please log in.");
  }

  // Construct API URL dynamically
  const apiUrl = `${apiBaseUrl.apiBaseUrl}/KitchenDisplay/GetOrdersByStatus?status=3`;
  console.log('Request URL:', apiUrl);

  try {
      // Fetch orders by status
      const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${authToken}`,  // Use token from localStorage
              'Content-Type': 'application/json',
          },
      });

      // Debugging information: check response status and headers
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);

      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);

      // Handle JSON response only if the correct content-type is returned
      if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          console.log('Fetched order details:', data);
          return data;  // Return the fetched order details
      } else {
          const responseBody = await response.text();
          console.error('Unexpected response format:', responseBody);
          throw new Error("Unexpected response format: Expected JSON");
      }
  } catch (error) {
      console.error('Error fetching order details:', error);
      return null;  // Return null on error
  }
};