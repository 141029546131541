import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap, FeatureGroup, LayersControl, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import L from 'leaflet';
import 'leaflet-draw';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { fetchDeliveryZonesByRestaurant, saveBoundary } from '../../Common/SharedApis/DeliveryAreaMapZone';
import { Card, CardBody, Col, Row, Spinner } from 'react-bootstrap';
import { MdOutlineMap } from 'react-icons/md';

const DeliveryAreaMap: React.FC = () => {
  const featureGroupRef = useRef<L.FeatureGroup>(null);
  const [selectedArea, setSelectedArea] = useState<number | null>(null);
  const [selectedShapeType, setSelectedShapeType] = useState<string | null>(null);
  const [deliveryZones, setDeliveryZones] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedZone, setSelectedZone] = useState<any | null>(null);
  const [isDrawingEnabled, setIsDrawingEnabled] = useState(false); // New state variable

  const restaurantId = 1;
  const mapRef = useRef<L.Map | null>(null);

  useEffect(() => {
    const fetchZones = async () => {
      try {
        const zones = await fetchDeliveryZonesByRestaurant(restaurantId);
        setDeliveryZones(zones);
      } catch (error) {
        console.error('Error fetching delivery zones:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchZones();
  }, []);

  const MapWithDrawControls = () => {
    const map = useMap();
  
    useEffect(() => {
      if (!map) return;
  
      let drawControl: L.Control.Draw | null = null;  // Control for adding zone layers
  
      if (featureGroupRef.current) {
        drawControl = new L.Control.Draw({
          edit: {
            featureGroup: featureGroupRef.current,
          },
          draw: {
            polygon: {},
            rectangle: {},
            circle: {},
            marker: false,
            polyline: false,
          },
        });
  
        if (isDrawingEnabled) {
          map.addControl(drawControl);
        } else {
          // Clear shapes if not drawing
          featureGroupRef.current.clearLayers();
        }
  
        // Event listener for when a shape is created
        map.on(L.Draw.Event.CREATED, (e: any) => {
          const layer = e.layer;
          featureGroupRef.current?.addLayer(layer);
  
          if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
            const latLngs = layer.getLatLngs();
            const flattenedLatLngs: L.LatLngLiteral[] = [];
            const flattenLatLngs = (latLngs: L.LatLng[] | L.LatLng[][] | L.LatLng[][][]) => {
              if (Array.isArray(latLngs[0])) {
                latLngs.forEach((latLngArray) => {
                  flattenLatLngs(latLngArray);
                });
              } else {
                (latLngs as L.LatLng[]).forEach((latLng) => {
                  flattenedLatLngs.push(latLng);
                });
              }
            };
            flattenLatLngs(latLngs);
            const area = L.GeometryUtil.geodesicArea(flattenedLatLngs);
            setSelectedArea(area / 1e6);
            setSelectedShapeType(layer instanceof L.Polygon ? 'Polygon' : 'Rectangle');
          } else if (layer instanceof L.Circle) {
            const radius = layer.getRadius();
            const area = Math.PI * Math.pow(radius, 2);
            setSelectedArea(area / 1e6);
            setSelectedShapeType('Circle');
          }
        });
      }
  
      return () => {
        if (drawControl) {
          map.removeControl(drawControl); // Clean up the control on unmount
        }
        map.off(L.Draw.Event.CREATED); // Clean up event listener
      };
    }, [map, isDrawingEnabled]);
  
    return null;
  };
  

  const toggleDrawing = () => {
    if (isDrawingEnabled) {
      // Clear layers when cancelling drawing
      featureGroupRef.current?.clearLayers();
    }
    setIsDrawingEnabled(!isDrawingEnabled); // Toggle drawing state
};

  const saveBoundaries = async () => {
    const layers = featureGroupRef.current?.getLayers();

    if (layers && layers.length > 0) {
      const shapeData = layers.map(layer => {
        if ('toGeoJSON' in layer) {
          return (layer as L.Polygon).toGeoJSON();
        }
        return null;
      }).filter(Boolean);

      console.log('All Shape Data:', shapeData);

      if (shapeData.length > 0) {
        const geoJson = JSON.stringify(shapeData[0]);
        const boundaryModel = {
          geoJson,
          restaurantId,
          active: true,
        };

        await saveBoundary(boundaryModel)
          .then(response => {
            console.log('Boundary saved successfully:', response);
          })
          .catch(error => {
            console.error('Error saving boundary:', error);
          });
      } else {
        console.log('No valid shapes to save');
      }
    } else {
      console.log('No shapes have been drawn');
    }
  };

  const printMap = async () => {
    const mapElement = document.querySelector('.leaflet-container') as HTMLElement;
    if (mapElement) {
      const canvas = await html2canvas(mapElement, { useCORS: true });
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF();
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('map_boundaries.pdf');
    }
  };

  const handleZoneClick = (zone) => {
    if (zone.geoJson) {
      console.log('zone.geoJson:', zone.geoJson);

      try {
        const parsedGeoJson = JSON.parse(zone.geoJson);
        console.log('Parsed GeoJSON:', parsedGeoJson);

        if (parsedGeoJson.type === 'Feature' && parsedGeoJson.geometry) {
          const geoJsonLayer = L.geoJSON(parsedGeoJson, {
            style: {
              color: 'blue',
              weight: 2,
              opacity: 0.7,
            },
          });

          featureGroupRef.current?.clearLayers();
          featureGroupRef.current?.addLayer(geoJsonLayer);

          if (mapRef.current) {
            const bounds = geoJsonLayer.getBounds();
            if (bounds.isValid()) {
              mapRef.current.fitBounds(bounds);
            }
          } else {
            console.error('Map instance not found');
          }
        } else {
          console.error('Invalid GeoJSON format:', parsedGeoJson);
        }
      } catch (error) {
        console.error('Error parsing GeoJSON:', error);
      }
    } else {
      console.error('No GeoJSON found for the zone');
    }
  };

  return (
    <>
      <Row>
        <Col md={9}>
          <MapContainer
            center={[51.505, -0.09]}
            zoom={13}
            style={{ height: '600px', width: '100%' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; OpenStreetMap contributors"
            />
            <LayersControl position="topright">
              <LayersControl.Overlay checked name="Editable Layers">
                <FeatureGroup ref={featureGroupRef} />
              </LayersControl.Overlay>
            </LayersControl>

            {selectedZone && (
              <GeoJSON data={selectedZone} style={{ color: 'blue' }} />
            )}

            <MapWithDrawControls />
          </MapContainer>
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <button onClick={saveBoundaries} style={{ padding: '10px', fontSize: '16px' }}>
              Save Boundaries
            </button>

            {selectedArea !== null && (
              <div>
                <strong>Selected {selectedShapeType} Area:</strong> {selectedArea.toFixed(2)} square kilometers
              </div>
            )}
            <button onClick={printMap} style={{ padding: '10px', fontSize: '16px', marginTop: '10px' }}>
              Print Map
            </button>
          </div>
        </Col>
        <Col md={3}>
        <Card>
        <button className='btn btn-primary' onClick={toggleDrawing}>
          <MdOutlineMap style={{ marginRight: '8px' }} />
          {isDrawingEnabled ? 'Cancel Drawing' : 'Add New Zone'}
      </button>

        </Card>
          <Card>
            <Card.Body>

             
              <Card.Title>Delivery Zones</Card.Title>
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                deliveryZones.length > 0 ? (
                  deliveryZones.map((zone, index) => (
                    <div key={index} onClick={() => handleZoneClick(zone)}>
                      <p><strong>Zone ID:</strong> {zone.zoneId}</p>
                      <p><strong>Active:</strong> {zone.active ? 'Yes' : 'No'}</p>
                      <p><strong>Created Date:</strong> {new Date(zone.createdDate).toLocaleString()}</p>
                      <hr />
                    </div>
                  ))
                ) : (
                  <p>No delivery zones available.</p>
                )
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DeliveryAreaMap;
