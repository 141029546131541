import React, { useState } from "react";
import { Alert, Button, TextField } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from 'react-router-dom';
import { register } from "../../../Common/SharedApis/Auth";
import { registerRestaurant } from "../../../Common/SharedApis/MyAllResutrant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [responseMessage, setResponseMessage] = useState("");

  // Extract location data
  const { restaurantName, address, latitude, longitude } = location.state || {};

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseMessage("");
  
    const dataToSend = {
      FirstName: values.firstName,
      LastName: values.lastName,
      Email: values.email,
      Password: values.password,
    };
  
    try {
      const response = await register(dataToSend);
      setResponseMessage("Sign Up successful!");
      const userId = response.userId; // Ensure this matches your API response
      const token = response.token;
  
      // Store the token and userId in local storage
    //  localStorage.setItem("authToken", token);
    //  localStorage.setItem("userId", userId); // Save userId in local storage
  
      // Fetch the data from location state
      const locationData = location.state; // Make sure to retrieve the state properly
      const { restaurantName, address, latitude, longitude } = locationData;
      

      console.log('locationData',locationData);

      // Call the register restaurant API with correct types
      await registerRestaurant(userId, restaurantName, 1, 0, address, latitude, longitude); // Adjust store_type as needed
  
      debugger;

      // Navigate to restaurant list page after registration
      navigate('/WAITINGSCREEN', { state: { userId } });
    } catch (error) {
      setResponseMessage(error instanceof Error ? error.message : "Unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignInClick = () => {
    navigate('/'); // Redirect to sign-in page
  };

  return (
    <div className="main-page">
      <div className="left-section">
      <img src="/sumbite_white_logo.png" alt="SumBite Logo" className="logo" />
        <p>Join Us and Stay In Control</p>
        <p>Become a Part of the SumBite Family!</p>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      <div className="right-section">
        <form className="login-form" onSubmit={handleSubmit}>

              {/* Display error message as an Alert */}
        {responseMessage && (
          <Alert variation="error" isDismissible={true}>
            {responseMessage}
          </Alert>
        )}

          <h2>Complete Your Registration</h2>
          <p>You're just a few steps away from getting your restaurant listed!</p>
          <div className="name-fields">
          <TextField
            value={values.firstName}
            onChange={handleInputChange}
            name="firstName"
            label="First Name"
            type="text"
            width="100%"
            required
            className="text-field"
          />
          <TextField
            value={values.lastName}
            onChange={handleInputChange}
            name="lastName"
            label="Last Name"
            type="text"
            width="100%"
            required
            className="text-field"
          />
          </div>
          <TextField
            value={values.email}
            onChange={handleInputChange}
            name="email"
            label="Email"
            type="email"
            width="100%"
            required
            className="text-field"
          />
          <TextField
            value={values.password}
            onChange={handleInputChange}
            name="password"
            label="Password"
            type="password"
            width="100%"
            required
            className="text-field"
          />

          <Button type="submit" className="margin-top button-style" isLoading={isLoading}>
            Sign Up
          </Button>

          <div className="margin-top login-link">
            <span>Already have an account? <a onClick={handleSignInClick} href="#">Sign in</a></span>
          </div>

       
        </form>
      </div>
    </div>
  );
};

export default SignUpPage;
