import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogContent, Button, Grid, Typography, Paper } from "@mui/material";
import "./Allresturents.css"; // Import your updated CSS file
import { getmyallresturent } from "../../../Common/SharedApis/MyAllResutrant";

const Allresturents: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userId =localStorage.getItem("userId");
  const [loading, setLoading] = useState(true);
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) {
      console.error("No userId found.");
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      try {
        const restaurantData = await getmyallresturent(userId);
        setRestaurants(restaurantData.restaurants || []);
      } catch (error) {
        setError("Failed to fetch your available branches. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, navigate]);

  const handleMoveToDashboard = (restaurant: any) => {
    const dataToSave = {
      city_id: restaurant.city_id,
      lat: restaurant.lat,
      lot: restaurant.lot,
      registredUserID: restaurant.registredUserID,
      restaurantId: restaurant.restaurantId,
      store_Name: restaurant.store_Name,
      store_area: restaurant.store_area,
      store_type: restaurant.store_type,
    };

    sessionStorage.setItem("selectedBranchId", restaurant.restaurantId);
    localStorage.setItem("restaurantData", JSON.stringify(dataToSave));
    navigate("/dashboard");
  };

  const handleModalClose = (event: object, reason: string) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    setIsModalOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className={`restaurant-container ${isModalOpen ? "blur-background" : ""}`}>
      <Dialog open={isModalOpen} onClose={handleModalClose} fullWidth maxWidth="lg">
        <DialogContent>
          <Typography variant="h4" gutterBottom>Select a Branch to Proceed</Typography>
          <Typography variant="body1" gutterBottom>
            Please choose from your available branches to access their dashboard.
          </Typography>
          {restaurants.length > 0 ? (
            <Grid container spacing={3}>
              {restaurants.map((restaurant) => (
                <Grid item xs={12} sm={6} md={4} key={restaurant.restaurantId}>
                  <Paper elevation={3} className="restaurant-card">
                    <Typography variant="h6">{restaurant.store_Name}</Typography>
                    <Typography variant="body2">Address: {restaurant.store_area || "N/A"}</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      className="open-branch-button"
                      onClick={() => handleMoveToDashboard(restaurant)}
                    >
                      Open Branch
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No branches found. Please contact support for assistance.</Typography>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Allresturents;
