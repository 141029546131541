import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Accordion } from "react-bootstrap";
import {
  AddTopping,
  GetChoicesGroupsByRestaurant,
} from "../../Common/SharedApis/ChoicesAndAddons";
import AddChoicesGroupPopup from "./AddChoicesGroupPopup"; // Adjust the import path

interface AddOnesgroupsModelProps {
  show: boolean;
  handleClose: () => void;
  restaurantId: number;
  onSelectedGroupsChange: (selectedGroups: number[]) => void;
}

const AddOnesgroupsModel: React.FC<AddOnesgroupsModelProps> = ({
  show,
  handleClose,
  restaurantId,
  onSelectedGroupsChange,
}) => {
  const [choices, setChoices] = useState<any[]>([]);
  const [isAddingGroup, setIsAddingGroup] = useState<string | null>(null);
  const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
  const [newChoice, setNewChoice] = useState({ name: "", price: 0, preSelect: false });
  const [error, setError] = useState<string | null>(null);
  const [showAddGroupPopup, setShowAddGroupPopup] = useState(false); // State for the Add Group popup
  const [showAddChoicePopup, setShowAddChoicePopup] = useState<{
    groupId: number | null;
    show: boolean;
  }>({ groupId: null, show: false }); // State for Add Choice popup
  const selectedBranchId = sessionStorage.getItem("selectedBranchId");

  // Fetch choices and addons
  const fetchChoices = async () => {
    try {
      if (!restaurantId) {
        throw new Error("Restaurant ID is not provided");
      }

      const data = await GetChoicesGroupsByRestaurant(Number(selectedBranchId));

      if (Array.isArray(data)) {
        setChoices(data);
      } else {
        console.error("API returned invalid data:", data);
        setChoices([]);
      }
    } catch (err: any) {
      setError(err.message || "Error fetching choices and addons");
      setChoices([]);
    }
  };

  useEffect(() => {
    if (show) {
      fetchChoices();
    }
  }, [show, restaurantId]);

  const handleGroupSelect = (group: any) => {
    const isAlreadySelected = selectedGroups.some(
      (selectedGroup) => selectedGroup.choicesAddsOnsGroupId === group.choicesAddsOnsGroupId
    );
    const updatedSelection = isAlreadySelected
      ? selectedGroups.filter(
          (selectedGroup) => selectedGroup.choicesAddsOnsGroupId !== group.choicesAddsOnsGroupId
        )
      : [...selectedGroups, group];

    setSelectedGroups(updatedSelection);
    onSelectedGroupsChange(updatedSelection);
  };

  const handleAddChoice = async (choiceData: { name: string; price: number; groupId: number }) => {
    try {
      if (!restaurantId || !choiceData.groupId) {
        throw new Error("Missing required data to add choice");
      }

      await AddTopping({
        toppingName: choiceData.name,
        toppingPrice: choiceData.price,
        restaurant_id: Number(selectedBranchId),
        choicesAddsOnsGroupId: choiceData.groupId,
      });

      fetchChoices(); // Refresh choices after adding a topping
      setShowAddChoicePopup({ groupId: null, show: false }); // Close popup
    } catch (error) {
      console.error("Error adding choice:", error);
      setError("Failed to add choice. Please try again.");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setNewChoice({
      ...newChoice,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" className="settings-modal-custom">
      <Modal.Header closeButton>
        <Modal.Title>Choices & Addons</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="text-danger">{error}</div>}
        {/* "Create New Group" Button */}
        <div className="d-flex justify-content-end mb-3">
          <Button variant="primary" onClick={() => setShowAddGroupPopup(true)}>
            Create New Group
          </Button>
        </div>
        <Accordion>
          {Array.isArray(choices) &&
            choices.map((group) => (
              <Accordion.Item key={group.choicesAddsOnsGroupId} eventKey={group.groupName}>
                <Accordion.Header>{group.groupName}</Accordion.Header>
                <Accordion.Body>
                  {group.toppings.map((topping) => {
                    const price = Number(topping.toppingPrice);
                    return (
                      <div
                        key={topping.toppingID}
                        className="d-flex justify-content-between align-items-center mb-2"
                      >
                        <span>{topping.toppingName}</span>
                        <span>${isNaN(price) ? "N/A" : price.toFixed(2)}</span>
                      </div>
                    );
                  })}
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      variant="outline-primary"
                      onClick={() =>
                        setShowAddChoicePopup({
                          groupId: group.choicesAddsOnsGroupId,
                          show: true,
                        })
                      }
                    >
                      Add Choice
                    </Button>
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      variant={
                        selectedGroups.some(
                          (selectedGroup) =>
                            selectedGroup.choicesAddsOnsGroupId === group.choicesAddsOnsGroupId
                        )
                          ? "success"
                          : "outline-primary"
                      }
                      onClick={() => handleGroupSelect(group)}
                    >
                      {selectedGroups.some(
                        (selectedGroup) =>
                          selectedGroup.choicesAddsOnsGroupId === group.choicesAddsOnsGroupId
                      )
                        ? "Deselect"
                        : "Select"}
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
        {/* Render AddChoicesGroupPopup */}
        {showAddGroupPopup && (
          <AddChoicesGroupPopup
            closePopup={() => setShowAddGroupPopup(false)}
            fetchChoicesGroups={fetchChoices}
            restaurantId={Number(selectedBranchId)}
          />
        )}
        {/* Add Choice Popup */}
        {showAddChoicePopup.show && (
          <Modal
            show={showAddChoicePopup.show}
            onHide={() => setShowAddChoicePopup({ groupId: null, show: false })}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Choice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={newChoice.name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    value={newChoice.price}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowAddChoicePopup({ groupId: null, show: false })}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  handleAddChoice({
                    name: newChoice.name,
                    price: newChoice.price,
                    groupId: showAddChoicePopup.groupId!,
                  })
                }
              >
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="custom-button-secondary">
          Close
        </Button>
        <Button variant="primary" className="custom-button">
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOnesgroupsModel;
