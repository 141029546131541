import axios from 'axios';
import apiBaseUrl from '../ApiBaseUrl';

// Function to get auth token dynamically from localStorage
const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const fetchItems = async (restaurantId: number) => {
  const authToken = getAuthToken();  // Get token dynamically

  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemsByRestaurant?RestaurantId=${restaurantId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      return Array.isArray(data) ? data : [];
    } else {
      throw new Error("Unexpected response format: Expected JSON");
    }
  } catch (error) {
    console.error('Error fetching items:', error);
    return [];  // Return empty data on error
  }
};

export const fetchItemById = async (itemId: string) => {
  const authToken = getAuthToken();  // Get token dynamically

  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemById?itemId=${itemId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching item details:', error);
    throw error;
  }
};

export const GetItemWithChoicesAndToppingsById = async (itemId: number) => {
  const authToken = getAuthToken();  // Get token dynamically

  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemWithChoicesAndToppingsById?itemId=${itemId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching item details:', error);
    throw error;
  }
};

export const fetchItemByCategoryId = async (RestaurantId: string, CategoryId: string) => {
  const authToken = getAuthToken();  // Get token dynamically

  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemsByRestaurantIdAndCategory?RestaurantId=${RestaurantId}&CategoryId=${CategoryId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching item details:', error);
    throw error;
  }
};

export const updateItem = async (formData: FormData) => {
  const authToken = getAuthToken();  // Get token dynamically

  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const headers = {
    'Authorization': `Bearer ${authToken}`,
    'Content-Type': 'multipart/form-data', // Ensure the Content-Type is set to multipart/form-data
  };

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/UpdateItem`;

  try {
    const response = await axios.post(apiUrl, formData, { headers });
    return response.data;
  } catch (error) {
    console.error("Error updating item:", error);
    throw error;
  }
};

export const addItem = async (formData: FormData) => {
  const authToken = getAuthToken();  // Get token dynamically

  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const headers = {
    'Authorization': `Bearer ${authToken}`,
    'Content-Type': 'multipart/form-data', // Ensure the Content-Type is set to multipart/form-data
  };

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/AddRestaurantItems`;

  try {
    const response = await axios.post(apiUrl, formData, { headers });
    return response.data;
  } catch (error) {
    console.error("Error adding item:", error);
    throw error;
  }
};

export const GetItemsWithCategoryByRestaurantId = async (restaurantId: number) => {
  const authToken = getAuthToken();  // Get token dynamically

  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Items/GetItemsWithCategoryByRestaurantId?RestaurantId=${restaurantId}`;

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching items by restaurant ID:', error);
    throw error;
  }
};
