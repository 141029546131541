import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, TextField } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import ReactDOMServer from "react-dom/server";
import "./Location.css";

mapboxgl.accessToken = 'pk.eyJ1IjoidGhlbWV0YXN1bSIsImEiOiJjbTFiczFtMmQwZHdnMmxzNmRsZ292d3hiIn0.XvXHgjczGqyH7DPS8aeN_A';

const Location: React.FC = () => {
  const navigate = useNavigate();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [hasDraggedMap, setHasDraggedMap] = useState(false);
  const [values, setValues] = useState({
    restaurantName: location.state?.restaurantName || "",
    address: "",
    latitude: 0,
    longitude: 0,
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [markerPosition, setMarkerPosition] = useState<[number, number]>([0, 20]);

  const fetchAddress = async (longitude: number, latitude: number): Promise<string> => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${mapboxgl.accessToken}`
      );
      const data = await response.json();
      return data.features[0]?.place_name || "Unknown location";
    } catch (error) {
      console.error("Error fetching address:", error);
      return "Unable to fetch address";
    }
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLDivElement,
      style: "mapbox://styles/mapbox/streets-v11",
      projection: "globe",
      center: [0, 20],
      zoom: 22,
      attributionControl: false,
    });

    const pinIconContainer = document.createElement("div");
    pinIconContainer.className = "mapboxgl-marker-icon";

    const pinIcon = (
      <FontAwesomeIcon
        icon={faMapPin}
        style={{ fontSize: "30px", color: "red", cursor: "pointer" }}
      />
    );

    pinIconContainer.innerHTML = ReactDOMServer.renderToString(pinIcon);

    const marker = new mapboxgl.Marker({ element: pinIconContainer })
      .setLngLat(markerPosition)
      .addTo(map);

    if (location.state?.coords) {
      const [longitude, latitude] = location.state.coords;
      map.flyTo({ center: [longitude, latitude], zoom: 15 });
      marker.setLngLat([longitude, latitude]);
      setMarkerPosition([longitude, latitude]);
      setValues((prev) => ({ ...prev, latitude, longitude }));
    }

    map.on("move", () => {
      const center = map.getCenter();
      marker.setLngLat(center);
    });

    map.on("dragstart", () => {
      setIsDragging(true);
    });

    map.on("dragend", async () => {
      setIsDragging(false);
      const markerLngLat = marker.getLngLat();
      setMarkerPosition([markerLngLat.lng, markerLngLat.lat]);
      setValues((prev) => ({
        ...prev,
        latitude: markerLngLat.lat,
        longitude: markerLngLat.lng,
      }));

      const address = await fetchAddress(markerLngLat.lng, markerLngLat.lat);
      setValues((prev) => ({ ...prev, address }));

      setHasDraggedMap(true);
    });

    return () => map.remove();
  }, [location.state]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    console.log("Submitted Data:", values);

    navigate("/signup", { state: values });

    setIsLoading(false);
  };

  return (
    <div className="main-page">
      <div className="right-section">
        <img src="/logo.png" alt="SumBite Logo" className="location-logo" />

        <form className="login-form" onSubmit={handleSubmit}>
          {responseMessage && (
            <Alert variation="error" isDismissible={true}>
              {responseMessage}
            </Alert>
          )}
          <h2>Pin Your Restaurant on the Map</h2>
          <p className="subtitle">
            Provide your restaurant's name and location details to help customers find you easily.
          </p>
          <TextField
            value={values.restaurantName}
            onChange={handleInputChange}
            name="restaurantName"
            label="Restaurant Name"
            type="text"
            width="100%"
            required
            placeholder="Enter the name of your restaurant"
            className="text-field"
          />
          <TextField
            value={values.address}
            name="address"
            label="Address"
            type="text"
            width="100%"
            required
            placeholder="Drag the map to get the address"
            className="text-field"
            readOnly
          />
          <Button
            type="submit"
            className="margin-top button-style"
            isLoading={isLoading}
            disabled={!hasDraggedMap || isDragging}
          >
            Next
          </Button>
        </form>
      </div>
      <div className="left-sections">
        <h3>Visualize Your Location</h3>
        <p>
          Use the interactive map to confirm your restaurant's exact location. Drag and zoom to adjust the view as needed.
        </p>
        <div ref={mapContainerRef} className="map-container" />
      </div>
    </div>
  );
};

export default Location;
