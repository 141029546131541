import React, { createContext, useEffect, useContext, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import createSignalRConnection from './signalRService';
import { useAudio } from './AudioContext';

const SignalRContext = createContext<{ connection: signalR.HubConnection | null, startConnection: () => void } | null>(null);

export const useSignalR = () => useContext(SignalRContext);

const getExponentialBackoffDelay = (attempt: number) => {
  const baseDelay = 5000; // 5 seconds
  return Math.min(baseDelay * 2 ** attempt, 60000); // Maximum 1 minute delay
};



export const SignalRProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const { playNotification } = useAudio(); // Get the playNotification function
  const broadcastListenerAddedRef = useRef<boolean>(false);
  let broadcastListenerAdded = false;

  const startConnection = () => {
    if (connection) {
      return;
    }
    const token = localStorage.getItem('authToken')?.toString() || "";
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    const isTokenValid = token && tokenExpiration && new Date(tokenExpiration) > new Date();

    if (token !== "" && !isTokenValid) {
      return;
    }

    const newConnection = createSignalRConnection(token);
    if (!newConnection) {
      return;
    }

    setConnection(newConnection);

    const tryConnect = () => {
      newConnection.start()
        .then(() => {
          console.log("Connected to SignalR Hub");
          if (!broadcastListenerAdded && !broadcastListenerAddedRef.current) {
            newConnection.on("broadcastmessage", (type, payload) => {
              playNotification();
              console.log("Notification received: ", type, payload);
              toast.success("Notification: " + type + " " + payload);
            });
            broadcastListenerAdded = true;
            broadcastListenerAddedRef.current = true;
          }
        })
        .catch(err => {
          console.error("Error connecting to SignalR Hub: ", err);
          setRetryAttempt(prev => prev + 1);
          const delay = getExponentialBackoffDelay(retryAttempt);
          toast.error("Failed to connect to the server. Retrying...");
          setTimeout(tryConnect, delay);
        });
    };
    tryConnect();

    newConnection.onreconnecting((error) => {
      console.warn(`Connection lost due to error "${error}". Reconnecting...`);
      toast.warn("Connection lost. Reconnecting...");
    });

    newConnection.onreconnected((connectionId) => {
      console.log(`Connection reestablished. Connected with connectionId ${connectionId}.`);
      toast.success("Reconnected to the server.");
      setRetryAttempt(0); // Reset retry attempt on successful reconnection
    });

    newConnection.onclose(() => {
      console.warn("Connection closed.");
      toast.error("Connection closed. Trying to reconnect...");
      setRetryAttempt(prev => prev + 1);
      setTimeout(tryConnect, getExponentialBackoffDelay(retryAttempt));
    });
  };

  useEffect(() => {
    if (!connection) {
      startConnection();
    }
    return () => {
      if (connection) {
        connection.stop();
        connection.off("broadcastmessage");
        broadcastListenerAddedRef.current = false;

      }
    };
  }, [connection]);

  return (
    <SignalRContext.Provider value={{ connection, startConnection }}>
      {children}
    </SignalRContext.Provider>
  );
};