import React, { useState, useEffect, useRef } from "react";
// import './BranchesPractice.css';
import { Form, Row, Col, Button } from "react-bootstrap";
import Map from "./Map";

const ThreeStepForm: React.FC = () => {

    const [currentStep, setCurrentStep] = useState<number>(1);
    const nextStep = () => {
        if (currentStep < 3) {
          setCurrentStep(currentStep + 1);
        }
      };
      const prevStep = () => {
        if (currentStep > 1) {
          setCurrentStep(currentStep - 1);
        }
      };

return (
<>
<h1>Create a New Branch</h1>
<Row>
<Col
    lg={8}
    style={{
    maxHeight: "100vh", backgroundColor: "white", borderRadius: "10px", boxShadow: "15px gray",padding: "5%", margin: "auto",
    }}
    >
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
    <div
    style={{ width: "40px", height: "40px",backgroundColor: currentStep >= 1 ? "#ff5722" : "#ddd", borderRadius: "20px", display: "flex",justifyContent: "center", alignItems: "center",
    }}
    >
    1
    </div>
    <div
    style={{ width: "40%", height: "8px", margin: "auto 0px", backgroundColor: currentStep >= 2 ? "#ff5722" : "#ddd",
    }}
    ></div>
    <div
    style={{ width: "40px", height: "40px",backgroundColor: currentStep >= 2 ? "#ff5722" : "#ddd", borderRadius: "20px", display: "flex",justifyContent: "center", alignItems: "center",
    }}
    >
         2
    </div>
    <div
     style={{
     width: "40%",
     height: "8px",
     margin: "auto 0px",
    backgroundColor: currentStep >= 3 ? "#ff5722" : "#ddd",
    }}
    ></div>
     <div
    style={{
      width: "40px",
        height: "40px",
        backgroundColor: currentStep >= 3 ? "#ff5722" : "#ddd", borderRadius: "20px",
        display: "flex", justifyContent: "center",
        alignItems: "center",
        }}
        >
        3
        </div>
    </div>

{currentStep === 1 && (
    <Form>
    <Form.Group controlId="restaurantName">
        <Form.Label>Restaurant Name</Form.Label>
        <Form.Control
        type="text" placeholder="Enter restaurant name" name="restaurantName"/>
    </Form.Group>
    <Form.Group controlId="restaurantAddress">
        <Form.Label>Restaurant Address</Form.Label>
        <Form.Control
        type="text" placeholder="Enter restaurant address" name="restaurantAddress"/>
    </Form.Group>

    <Button variant="primary" onClick={nextStep}>Next</Button>

</Form>
)}

{currentStep === 2 && (
<>

<Map/>
    <Button variant="secondary" onClick={prevStep}>Back</Button>
    <Button variant="primary" onClick={nextStep}>Next</Button>
</>
)}

{currentStep === 3 && (
    <div>
    <div>Page number two of the form</div>
    <Button variant="primary" onClick={prevStep}>Back</Button>

    </div>
)}


</Col>
</Row>
</>
);
};

export default ThreeStepForm;