import React, { useState } from "react";
import { AddChoicesAddsOnsGroup } from "../../Common/SharedApis/ChoicesAndAddons";
import "./choicesGroup.css";

interface AddChoicesGroupPopupProps {
  closePopup: () => void;
  fetchChoicesGroups: () => void; // Function to refresh the choices groups list
  restaurantId: number; // Restaurant ID to associate with the group
}

const AddChoicesGroupPopup: React.FC<AddChoicesGroupPopupProps> = ({
  closePopup,
  fetchChoicesGroups,
  restaurantId,
}) => {
  const [groupName, setGroupName] = useState("");
  const [isMandatory, setIsMandatory] = useState(false); // Toggle between Optional and Mandatory
  const [forceMin, setForceMin] = useState(1);
  const [forceMax, setForceMax] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleSubmit = async () => {
    if (!groupName.trim()) {
      setError("Please enter a valid group name.");
      return;
    }

    if (isMandatory && (forceMin <= 0 || forceMax <= 0 || forceMin > forceMax)) {
      setError("Force Min and Force Max must be valid and Force Min cannot exceed Force Max.");
      return;
    }

    setLoading(true);
    setError("");

    const groupData = {
      choicesAddsOnsGroupId: 0, // Assuming 0 for new group creation
      restaurantId,
      groupName: groupName.trim(),
      isOptional: !isMandatory, // Optional is true when Mandatory is false
      allowAddingMultipleTimes: false, // Assuming no multiple additions for now
      active: true,
      forceMin: isMandatory ? forceMin : 0,
      forecMax: isMandatory ? forceMax : 0,
    };

    try {
      await AddChoicesAddsOnsGroup(groupData);
      fetchChoicesGroups(); // Refresh the groups list
      closePopup(); // Close the popup
    } catch (err) {
      setError("Unable to add the group. Please try again later.");
      console.error("Error adding Choices Group:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="choices-group-popup-overlay">
      <div className="choices-group-popup">
        <h3 className="popup-title">Add New Choices Group {restaurantId}</h3>
        <input
          type="text"
          placeholder="Eg. Extra toppings, Choose type"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className="popup-input"
          maxLength={80}
        />
        <div className="group-type-toggle">
          <button
            className={`toggle-button ${!isMandatory ? "active" : ""}`}
            onClick={() => setIsMandatory(false)}
          >
            Optional
          </button>
          <button
            className={`toggle-button ${isMandatory ? "active" : ""}`}
            onClick={() => setIsMandatory(true)}
          >
            Mandatory
          </button>
        </div>
        {isMandatory && (
          <div className="force-values-container">
            <div className="force-input">
              <label>Force Minimum</label>
              <input
                type="number"
                value={forceMin}
                min={1}
                onChange={(e) => setForceMin(Number(e.target.value))}
              />
            </div>
            <div className="force-input">
              <label>Force Maximum</label>
              <input
                type="number"
                value={forceMax}
                min={1}
                onChange={(e) => setForceMax(Number(e.target.value))}
              />
            </div>
          </div>
        )}
        {error && <p className="error-message">{error}</p>}
        <div className="popup-actions">
          <button onClick={closePopup} className="popup-close-button">
            Cancel
          </button>
          <button onClick={handleSubmit} disabled={loading} className="popup-submit-button">
            {loading ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddChoicesGroupPopup;
