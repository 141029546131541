import apiBaseUrl from '../ApiBaseUrl';

const token = localStorage.getItem('authToken')?.toString();


const handleResponse = async (response: Response) => {
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error fetching data: ${response.statusText} - ${errorText}`);
    }
    return await response.json();
};

export const getmyallresturent = async (userId: string) => {
    const token = localStorage.getItem("authToken"); // Ensure you use the correct token key
    console.log("Retrieved token:", token); // Log the token for debugging

    if (!token) {
        console.error("No authentication token found.");
        throw new Error("No authentication token found.");
    }

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
    };

    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/GetMyAllRegisteredRestaurants?userId=${userId}`, {
            headers,
        });
        
        if (!response.ok) {
            console.error("Response not OK:", response.statusText);
            throw new Error("Failed to fetch restaurants");
        }

        const data = await response.json();
        console.log("Response data:", data); // Log the data retrieved
        return data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error; // Rethrow the error for handling in the calling function
    }
};


  
export const registerRestaurant = async (userId, storeName, storeType, cityId, storeArea, lat, lot) => {
    const authToken = localStorage.getItem("authToken");

    debugger;
    
    console.log("Retrieved token:", authToken); // Log the token

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${authToken}`, // Include the token in the Authorization header
    };

    const body = JSON.stringify({
        store_Name: storeName,
        store_type: storeType,
        city_id: cityId,
        store_area: storeArea,
        registredUserID: userId,
        lat: lat,
        lot: lot,
    });

    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/RegisterRestaurant`, {
            method: "POST",
            headers,
            body,
        });

        // Check response
        if (!response.ok) {
            const errorText = await response.text();
            console.error("Response not OK:", response.statusText, errorText);
            throw new Error(`Failed to register restaurant: ${errorText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error registering restaurant:", error);
        throw error; // Rethrow the error for handling in the calling function
    }
};


  
  
