import React, { useState } from 'react';

const OrderType: React.FC = () => {
  const [orderType, setOrderType] = useState<string>('Dine In');

  const handleOrderTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOrderType(event.target.value);
  };

  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <div className="col-md-6">
        <h5 className="mb-3">Choose Dishes</h5>
      </div>
      <div className="col-md-6">
        <select 
          className="form-select" 
          value={orderType} 
          onChange={handleOrderTypeChange}
          aria-label="Default select example"
        >
          <option value="Dine In">Dine In</option>
          <option value="1">To Go</option>
          <option value="2">Deliver</option>
        </select>
      </div>
    </div>
  );
};

export default OrderType;
