import React, { useEffect, useState } from 'react';
import ItemsWithCategory from './ItemsWithCategory'; // Adjust the path if necessary
import { GetItemsWithCategoryByRestaurantId } from '../../Common/SharedApis/Item'; // Replace with your actual API method

interface Category {
  id: number;
  name: string;
  items: number;
}

interface Item {
  itemId:number,
  name: string;
  price: number;
  description: string;
  image: string;
}

const DisplayItemsMenu: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [items, setItems] = useState<Record<string, Item[]>>({});
  const selectedBranchId = Number(sessionStorage.getItem("selectedBranchId"));
 
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const data = await GetItemsWithCategoryByRestaurantId(selectedBranchId);

        const transformedCategories = data.map((category: any) => ({
          id: category.categoryId,
          name: category.categoryName,
          items: category.items.length,
        }));

        const transformedItems = data.reduce((acc: Record<string, Item[]>, category: any) => {
          acc[category.categoryName] = category.items.map((item: any) => ({
            itemId: item.itemId,
            name: item.itemName,
            price: item.itemPrice,
            description: item.varients ? 'Multiple variants available' : 'No variants',
            image: item.largeImage || '/default-image.jpg',
          }));
          return acc;
        }, {});

        setCategories(transformedCategories);
        setItems(transformedItems);
      } catch (error) {
        console.error('Error fetching items by restaurant ID:', error);
      }
    };

    fetchItems();
  }, [selectedBranchId]);

  return (
    <div>
      <h1>Menu</h1>
      <ItemsWithCategory categories={categories} items={items} />
    </div>
  );
};

export default DisplayItemsMenu;
