import React from "react";
import { View } from "@aws-amplify/ui-react";
import Menuepage from "./Menuepage";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { MdMargin, MdPadding } from "react-icons/md";
// import { lightBlue } from "@mui/material/colors";

const Menue = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const restaurantData = JSON.parse(localStorage.getItem('restaurantData') || '{}');
  const restaurantName = restaurantData?.store_Name || 'Unknown Restaurant'; // Default to 'Unknown Restaurant' if not found


  return (
    <>
      {/* Header section */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems:'center', margin: "20px 0", padding: "10px"  }}>
        <h6>Add Your {restaurantName}  Menu Item</h6>    
      </div>

      {/* Main content section */}
      <View
        borderRadius="6px"
        maxWidth={{ base: "100%", large: "100%" }}
        maxHeight="100%"
      >
        <br />
        <Menuepage />
      </View>
    </>
  );
};

export default Menue;
