import React, { useState } from "react";
import { View } from "@aws-amplify/ui-react";
import Pagination from "@mui/material/Pagination";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import "./order.css"; // Import your CSS file

// Define the Order type
interface Order {
  id: number;
  order_id: number; // Assuming this is the ID you want to pass
  order_datetime: string;
  restaurant_id: number | null;
  user_id: number | null;
  order_status_description: string;
  fromResturant: string;
  deliveryAddress: string | null;
  orderTypeName: string;
  price:Number
}

interface OrderlistProps {
  orders: Order[];
  isLoading: boolean;
  totalPages: number;
  pageSize: number;
  handlePageChange: (newPage: number) => void;
}

const Orderlist: React.FC<OrderlistProps> = ({
  orders,
  isLoading,
  totalPages,
  pageSize,
  handlePageChange,
}) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  // Status colors mapping
  const statusColors: Record<string, string> = {
    "Pending": "#FEEFED",
    "Processing": "#FFE4B3",
    "Shipped": "#DBF2EA",
    "Delivered": "#C8E6C9",
    "Cancelled": "#E0E0E0",
    "Returned": "#FFF9C4",
    "Refunded": "#BBDEFB",
    "OnHold": "#E1BEE7",
    "Completed": "#388E3C",
    "Failed": "#D32F2F",
  };

  const getTextColor = (backgroundColor: string) => {
    const color = (parseInt(backgroundColor.replace('#', ''), 16) > 0xFFFFFF / 2) ? '#000000' : '#FFFFFF';
    return color;
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "order_datetime", headerName: "Order Date", flex: 2 },
    { field: "deliveryAddress", headerName: "Delivery Address", flex: 2 },
    { field: "price", headerName: "Price", flex: 1 },
    { field: "fromResturant", headerName: "From Restaurant", flex: 2 },
    { field: "orderTypeName", headerName: "Order Type", flex: 2 },
    {
      field: "order_status_description",
      headerName: "Order Status",
      flex: 2,
      renderCell: (params) => {
        const statusCode = params.value as string;
        const backgroundColor = statusColors[statusCode] || "#FFFFFF";
        const textColor = getTextColor(backgroundColor);
        return (
          <span
            style={{
              backgroundColor,
              color: textColor,
              padding: "5px 10px",
              borderRadius: "4px",
            }}
          >
            {params.value}
          </span>
        );
      }
    },
    {
      field: "view",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <button
          className="view-button"
          onClick={() => handleViewClick(params.row.order_id)} // Pass the order_id
        >
          View
        </button>
      ),
    },
  ];

  const handleViewClick = (order_id: number) => {
    console.log("Selected Order ID:", order_id); // Log the order_id
    navigate(`/NewOrderDetails/${order_id}`); // Pass order_id in the URL
  };

  const handleChangePage = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    handlePageChange(newPage);
  };

  // Pagination calculations
  const totalItems = orders.length;
  const startIndex = (page - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const displayedRows = orders.slice(startIndex, endIndex);
  const itemsShown = endIndex - startIndex;
  const pageCount = Math.ceil(totalItems / pageSize);

  return (
    <View
      borderRadius="6px"
      maxWidth="100%"
      minHeight="80vh"
    >
      <br />
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div>
            <DataGrid
              rows={displayedRows}
              columns={columns}
              pagination
              hideFooter={true}
            />
          </div>
          <div className="pagination-container">
            <div className="pagination-info">
              {`${itemsShown} of ${totalItems} shown`}
            </div>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChangePage}
              siblingCount={1}
              boundaryCount={1}
            />
          </div>
        </div>
      )}
    </View>
  );
};

export default Orderlist;
