import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import { fetchItems } from '../../Common/SharedApis/Item';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './Item.css';

type MenuItem = {
  id: number;
  item_id: number;
  item_name: string;
  item_price: number;
  item_description: string;
};

const ItemPage = () => {
  const [items, setItems] = useState<MenuItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    const fetchMenuItems = async () => {
      if (authToken) {
        setLoading(true);
        try {
          const data = await fetchItems(1); // Replace 1 with the actual restaurant ID
          const itemsWithId = data.map((item: any, index: number) => ({
            ...item,
            id: index + 1,  // Unique ID for the grid
            itemName: item.item_name,
            price: item.item_price,
            description: item.item_description,
          }));
          setItems(itemsWithId);
        } catch (error) {
          console.error('Error fetching items:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchMenuItems();
  }, []);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
    setPage(1);
  };

  const deleteItem = (itemID: number) => {
    const authToken = localStorage.getItem('authToken');

    axios
      .delete(`https://restaurantapi.themetasum.com/api/MenuItems/${itemID}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then(() => {
        setItems((prevItems) => prevItems.filter((item) => item.item_id !== itemID));
      })
      .catch((error) => {
        console.error('Error deleting item:', error);
      });
  };

  const viewItemById = (itemID: number) => {
    const item = items.find((item) => item.item_id === itemID);
    if (item) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div
            className="item-popup"
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 20px 75px rgba(0, 0, 0, 0.13)',
              maxWidth: '400px',
              minWidth: '600px',
              margin: '0 auto',
            }}
          >
            <h2 className="popup-header" style={{ color: '#666' }}>Item Details</h2>
            <p className="popup-item">Item Id: {item.item_id}</p>
            <p className="popup-item">Name: {item.item_name}</p>
            <p className="popup-item">Price: {item.item_price}</p>
            <p className="popup-item">Description: {item.item_description || 'N/A'}</p>
            <button className="close-buttons" onClick={onClose}>
              Close
            </button>
          </div>
        ),
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    }
  };

  const confirmDeleteItem = (itemID: number) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this item?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteItem(itemID),
          style: {
            maxWidth: '70px',
            backgroundColor: '#ff4d4f', // Red color for Yes button
          },
        },
        {
          label: 'No',
          style: {
            maxWidth: '70px',
            backgroundColor: '#1890ff', // Blue color for No button
          },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const editItem = (itemID: number) => {
    navigate(`/Menue?itemId=${itemID}`);
  };

  const filteredItems = items
    .filter((item) => item.item_name?.toLowerCase().includes(filterValue.toLowerCase()))
    .slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const totalItems = items.length;
  const itemsShown = Math.min(page * itemsPerPage, totalItems);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageCount = Math.min(totalPages, 100);

  const columns: GridColDef[] = [
    { field: 'item_id', headerName: 'Item_ID', flex: 0.5 },
    { field: 'itemName', headerName: 'Item Name', flex: 1 },
    { field: 'price', headerName: 'Price', flex: 0.5 },
    {
      field: 'description',
      headerName: 'Description',
      flex: 3,
      sortable: false,
    },
    {
      field: 'view',
      headerName: 'View',
      flex: 0.75,
      sortable: false,
      renderCell: (params) => (
        <button onClick={() => viewItemById(params.row.item_id)} className="view-button">
          View
        </button>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 0.75,
      sortable: false,
      renderCell: (params) => (
        <button onClick={() => editItem(params.row.item_id)} className="edit-button">
          Edit
        </button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Action',
      flex: 0.75,
      sortable: false,
      renderCell: (params) => (
        <button onClick={() => confirmDeleteItem(params.row.item_id)} className="delete-button">
          Delete
        </button>
      ),
    },
    
  ];

  const handlePageChange = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleItemsPerPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setItemsPerPage(Number(event.target.value));
    setPage(1);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Items List</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="pagination-controls">
            <span className="pagination-label">Show</span>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="pagination-select">
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <span className="pagination-label">Pages</span>
          </div>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search items by name..."
            value={filterValue}
            onChange={handleFilterChange}
            className="search-input enhanced-search"
          />
          <span className="search-icon">&#128269;</span>
          {filterValue && (
            <button className="clear-buttons" onClick={() => setFilterValue('')}>
              &#10006;
            </button>
          )}
        </div>
      </div>
      <div className="data-grid-container">
        <div className="data-grid">
          <DataGrid rows={filteredItems} columns={columns} hideFooter />
        </div>
        <div className="pagination-container">
          <div className="pagination-info">
            {`${itemsShown} of ${totalItems} shown`}
          </div>
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePageChange}
            siblingCount={1}
            boundaryCount={1}
          />
        </div>
      </div>
    </div>
  );
};

export default ItemPage;
