import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Badge } from 'react-bootstrap';
import { FaTable, FaPlay, FaPause, FaPrint } from 'react-icons/fa';
import { GetKitchenDisplayOrdersByStatus } from '../../Common/SharedApis/KitchDisplayApi'; // Import your API function
import './KitchenDisplay.css'; // Adding custom CSS for card scroll behavior

const KitchenDisplay = () => {
  const [newOrders, setNewOrders] = useState([]);
  const [processOrders, setProcessOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch New Orders (status: 1)
        const newOrdersData = await GetKitchenDisplayOrdersByStatus(1);
        if (newOrdersData) {
          setNewOrders(newOrdersData);
        }

        // Fetch In Process Orders (status: 2)
        const processOrdersData = await GetKitchenDisplayOrdersByStatus(2);
        if (processOrdersData) {
          setProcessOrders(processOrdersData);
        }

        // Fetch Ready Orders (status: 3)
        const readyOrdersData = await GetKitchenDisplayOrdersByStatus(3);
        if (readyOrdersData) {
          setReadyOrders(readyOrdersData);
        }
      } catch (err) {
        // setError('Error fetching orders. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Function to render order details
  const renderOrder = (order, color) => (
    <Card className={`mb-3 border-${color}`} key={order.orderId}>
      <Card.Body>
        <Card.Title className={`text-${color}`}>
          <FaTable /> Table {order.table}{' '}
          <span className="float-end">Order #{order.orderId}</span>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{order.time}</Card.Subtitle>
        {/* Scrollable list of items */}
        <div className="scrollable-items">
          <ul>
            {order.items.map((item, index) => (
              <li key={index}>
                {item.quantity}x {item.name} {item.size && `(${item.size})`}
              </li>
            ))}
          </ul>
        </div>
        <Card.Text>
          {order.dineIn ? 'Dine In' : 'Takeaway'} - {order.timeRemaining}
        </Card.Text>
        {order.status === 'New' && (
          <Button variant="dark">
            <FaPlay /> Start
          </Button>
        )}
        {order.status === 'In Process' && (
          <Button variant="warning">
            <FaPause /> Pause
          </Button>
        )}
        {order.status === 'Ready' && (
          <Button variant="success">
            <FaPrint /> Print
          </Button>
        )}
      </Card.Body>
    </Card>
  );

  if (loading) {
    return <div>Loading orders...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container-fluid">
      {/* Order counters aligned to the right */}
      <Row className="mb-3">
        <Col className="d-flex justify-content-end">
          <Button variant="danger" className="me-2">
            New Orders <Badge bg="light" text="dark">{newOrders.length}</Badge>
          </Button>
          <Button variant="warning" className="me-2">
            In Process <Badge bg="light" text="dark">{processOrders.length}</Badge>
          </Button>
          <Button variant="success">
            Ready Orders <Badge bg="light" text="dark">{readyOrders.length}</Badge>
          </Button>
        </Col>
      </Row>

      {/* Order cards layout */}
      <Row>
        {/* New Orders Column */}
        <Col>
          <h4 className="text-danger">New Orders</h4>
          {newOrders.map((order) => renderOrder(order, 'danger'))}
        </Col>

        {/* In Process Column */}
        <Col>
          <h4 className="text-warning">In Process</h4>
          {processOrders.map((order) => renderOrder(order, 'warning'))}
        </Col>

        {/* Ready Orders Column */}
        <Col>
          <h4 className="text-success">Ready Orders</h4>
          {readyOrders.map((order) => renderOrder(order, 'success'))}
        </Col>
      </Row>
    </div>
  );
};

export default KitchenDisplay;
