import apiBaseUrl from '../ApiBaseUrl';

// Function to get auth token dynamically from localStorage
const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const fetchcategory = async (restaurantId: number) => {
  const authToken = getAuthToken();  // Get token dynamically

  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Category/GetCategoryByItemCount?RestaurantId=${restaurantId}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);

    const contentType = response.headers.get('Content-Type');
    console.log('Content-Type:', contentType);

    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      console.log('Fetched data:', data);

      return Array.isArray(data) ? data : [];
    } else {
      const responseBody = await response.text();
      console.error('Unexpected response format:', responseBody);
      throw new Error("Unexpected response format: Expected JSON");
    }
  } catch (error) {
    console.error('Error fetching category:', error);
    return []; // Return an empty array on error
  }
};

// Function to add a new category
export const addCategory = async (categoryName: string, restaurantId: number) => {
  const authToken = getAuthToken();  // Get token dynamically

  if (!authToken) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/Category/AddCategory`;
  console.log('Request URL:', apiUrl);

  const categoryData = {
    categoryName,
    restaurantId,
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(categoryData),  // Send category data as JSON
    });

    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);

    const contentType = response.headers.get('Content-Type');
    console.log('Content-Type:', contentType);

    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      console.log('Category added successfully:', data);
      return data;  // Return the response data (can be the newly created category or status)
    } else {
      const responseBody = await response.text();
      console.error('Unexpected response format:', responseBody);
      throw new Error("Unexpected response format: Expected JSON");
    }
  } catch (error) {
    console.error('Error adding category:', error);
    throw error;  // Rethrow error to handle it in the calling component or service
  }
};