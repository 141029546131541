import React, { useState } from "react";
import { TextField, Button, Alert } from "@aws-amplify/ui-react"; // Import Alert
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from "react-router-dom";
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { forgotPassword } from "../../../Common/SharedApis/Auth"; // Your API method for sending the email

const ForgotPasswordPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for button
  const [isError, setIsError] = useState(false); // State to track error/success
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage(""); // Reset message before request
    setIsError(false); // Reset error state before request
    try {
      // Call the forgotPassword API method to send a recovery code
      await forgotPassword(email);
      setMessage("A recovery code has been sent to your email.");
      // Navigate to ResetPasswordPage with email in state
      navigate('/reset-password', { state: { email } });
    } catch (error) {
      setIsError(true); // Set error state to true
      setMessage("Error sending code. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleSignInClick = () => {
    navigate('/');
  };

  return (
    <div className="main-page">
      {/* Left Section */}
      <div className="left-section">
        <h1>SumBite</h1>
        <p>Stay In Control</p>
        <p>Track orders in real-time from any device, manage tables, and streamline your restaurant operations effortlessly.</p>
        <div className="social-icons">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      {/* Right Section */}
      <div className="right-section">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2>Forgot Password</h2>

          {/* Display alert for success or error messages */}
          {message && (
            <Alert 
              variation={isError ? "error" : "success"} // Use error or success based on state
              isDismissible={true}
            >
              {message}
            </Alert>
          )}

          <TextField
            name="email"
            label="Enter Email"
            type="email"
            value={email}
            onChange={handleInputChange}
            required
            width="100%"
            className="text-field"
            placeholder="Enter your email"
          />
          <Button className="margin-top button-style" type="submit" isLoading={loading}>
            {loading ? "Sending..." : "Send Code"} {/* Show loading state in button */}
          </Button>
          <div className="links-container">
          <span><Link to="/">Back To Login</Link></span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
