import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './tracking.css';
import { TrackingOrdersByStatus } from '../../Common/SharedApis/TrackingOrder'; // Import the API method
import { useNavigate } from 'react-router-dom';

// Define types for order data
interface OrderItem {
  itemName: string;
  quantity: number;
}

interface Order {
  orderId: number;
  customerName: string;
  status: string;
  orderType: string;
  orderDateTime: string;
  specialInstructions: string;
  orderPriority: string;
  timeSinceOrder: string;
  items: OrderItem[];
  coords?: { // Make coords optional
    latitude: number;
    longitude: number;
  };
}

const riderIcon = 'https://cdn-icons-png.flaticon.com/512/2006/2006883.png';
const destinationIcon = 'https://cdn-icons-png.flaticon.com/512/25/25231.png';
const orderIcon = 'https://cdn-icons-png.flaticon.com/512/10375/10375479.png';

const riderMarker = new L.Icon({
  iconUrl: riderIcon,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const destinationMarker = new L.Icon({
  iconUrl: destinationIcon,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const orderMarker = new L.Icon({
  iconUrl: orderIcon,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const OrderTrackingleaflet = () => {
  const [riderData, setRiderData] = useState<{ currentLocation?: { latitude: number; longitude: number }; destination?: { latitude: number; longitude: number } }>({});
  const [selectedOrderCoords, setSelectedOrderCoords] = useState<{ latitude: number; longitude: number } | null>(null);
  const [mapViewState, setMapViewState] = useState({ center: [30.3753, 69.3451], zoom: 5 }); // Set initial view to Pakistan
  const [orders, setOrders] = useState<Order[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const lineData = selectedOrderCoords ? [
    [riderData.currentLocation?.latitude || 0, riderData.currentLocation?.longitude || 0],
    [selectedOrderCoords.latitude, selectedOrderCoords.longitude],
  ] : [];

  const handleOrderClick = (order: Order) => {
    if (order.coords) {
      setSelectedOrderCoords(order.coords);
      setRiderData((prev) => ({
        ...prev,
        destination: order.coords,
      }));
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const data = await TrackingOrdersByStatus(3);
        if (data) {
          setOrders(data);

          // Set the rider's current location based on the first order's coordinates
          const firstOrder = data[0];
          if (firstOrder.coords) {
            setRiderData({
              currentLocation: firstOrder.coords, // Assume the first order's coordinates as the current location
              destination: firstOrder.coords, // Set destination to the first order's coordinates initially
            });
            setSelectedOrderCoords(firstOrder.coords); // Set the selected order's coordinates
            setMapViewState({
              center: [firstOrder.coords.latitude, firstOrder.coords.longitude],
              zoom: 10, // Decrease the zoom level here
            });
          }
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);
  const handleButtonClick = () => {
    navigate("/ordertrackingmapbox"); // Use navigate to go to the "/Items" route
  };
  return (
    <div className="d-flex" style={{ height: '100vh' }}>
      <div className="col-3 p-3 border-right">
        <h6 className="text-center">Track Real-Time Orders</h6>
        <div className="mb-3">
          <label className="form-label">Track By Order ID</label>
          <input type="text" className="form-control" placeholder="Order ID" />
        </div>
        <div className="mb-3">
          <label className="form-label">Track By Rider</label>
          <input type="text" className="form-control" placeholder="Rider" />
        </div>
        <button className="btn btn-primary w-100 mb-2">Search</button>
        <button  className="btn btn-primary w-100" onClick={handleButtonClick}>Track Order On MapBox</button>

      </div>

      <div className="col-6 position-relative">
        {error && <div className="text-danger">{error}</div>}
        <MapContainer
          center={selectedOrderCoords 
            ? [selectedOrderCoords.latitude, selectedOrderCoords.longitude] 
            : [30.3753, 69.3451] // Default to Pakistan's coordinates
          }
          zoom={mapViewState.zoom}
          style={{ width: '100%', height: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {riderData.currentLocation && (
            <Marker
              position={[riderData.currentLocation.latitude, riderData.currentLocation.longitude]}
              icon={riderMarker}
            />
          )}
          {selectedOrderCoords && (
            <Marker
              position={[selectedOrderCoords.latitude, selectedOrderCoords.longitude]}
              icon={destinationMarker}
            />
          )}
          {orders.map((order) => (
            order.coords ? (
              <Marker
                key={order.orderId}
                position={[order.coords.latitude, order.coords.longitude]}
                icon={orderMarker}
                eventHandlers={{
                  click: () => handleOrderClick(order),
                }}
              />
            ) : null
          ))}
          {lineData.length > 0 && (
            <Polyline positions={lineData as [number, number][]} color="blue" weight={6} />
          )}
        </MapContainer>
      </div>

      <div className="orders-container col-3 p-3">
        <h6>On the Way Orders</h6>
        <div className="row">
          {loading ? (
            <div className="shimmer-wrapper">
              <div className="shimmer-card"></div>
              <div className="shimmer-card"></div>
              <div className="shimmer-card"></div>
            </div>
          ) : (
            orders.map((order) => (
              <div key={order.orderId} className="col-12">
                <div className="card card-custom" onClick={() => handleOrderClick(order)}>
                  <div className="card-body">
                    <h5 className="card-title card-title-custom">Order #{order.orderId}</h5>
                    <p className="card-text card-text-custom">{order.customerName} - {order.status}</p>
                    <p className="card-text card-price-custom"><strong>Priority: {order.orderPriority}</strong></p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderTrackingleaflet;
