// config.ts
const ApiBaseUrl = {
      apiBaseUrl: 'https://restaurantapi.themetasum.com/api',
   //   apiBaseUrl:'https://localhost:44379/api',
      //apiBaseUrl: 'https://cryptoapi.dmmbitusa.com/api',
     //apiBaseUrl:'https://localhost:44379/api',

     // signalRBaseUrl: 'https://localhost:44379',
      signalRBaseUrl: 'https://restaurantapi.themetasum.com',
  };
  
  export default ApiBaseUrl;
  