import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FaEnvelope, FaPhone, FaBuilding, FaQuestionCircle, FaPen } from 'react-icons/fa';

const ProfileCard = () => {
  return (
    <Col md={8}>
      <Card className="flex-fill p-4" style={{ backgroundColor: 'white', borderRadius: '10px', width: '740px', minHeight: '35vh'}}>
        <Row>
          {/* Profile Image */}
          <Col xs={3} className="d-flex align-items-center justify-content-center">
            <img
              src="https://via.placeholder.com/180"
              className="w-100 rounded-circle"
              alt="Eren Yeager"
            />
          </Col>
          {/* Profile Info */}
          <Col xs={9}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="fw-bold" style={{ color: '#3F4A5F' }}>Eren Yeager</h4>
                <p className="fw-semibold" style={{ color: '#00B87C', marginBottom: '0.5rem' }}>UX Designer</p>
              </div>
              {/* Edit and Question Icons */}
              <div>
                <Button variant="light" className="me-2 p-2 rounded-circle">
                  <FaQuestionCircle style={{ color: '#00B87C' }} />
                </Button>
                <Button variant="light" className="p-2 rounded-circle">
                  <FaPen style={{ color: '#B0B0B0' }} />
                </Button>
              </div>
            </div>
            <p className="text-muted small mb-2" style={{ color: '#B0B0B0' }}>St. Kings Road 57th, Garden Hills, Chelsea - London</p>

            {/* Contact Info */}
           <div className="d-flex mb-2 mt-5">
            {/* Email */}
            <div className="d-flex align-items-center me-4">
              <FaEnvelope style={{ color: '#00B87C', marginRight: '8px' }} />
              <a href="mailto:eren.yeager@mail.co.id" style={{ textDecoration: 'none', color: '#3F4A5F' }}>
                eren.yeager@mail.co.id
              </a>
            </div>
            {/* Phone Number */}
            <div className="d-flex align-items-center me-4 small">
              <FaPhone style={{ color: '#00B87C', marginRight: '8px' }} />
              <span style={{ color: '#3F4A5F' }}>+012 345 6789</span>
            </div>
            {/* Company */}
            <div>
              <FaBuilding style={{ color: '#F65C5C', marginRight: '8px' }} />
              <span style={{ color: '#3F4A5F' }}>Highspeed Studios</span>
            </div>

            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default ProfileCard;
