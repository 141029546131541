import apiBaseUrl from '../ApiBaseUrl';
var token = localStorage.getItem('authToken')?.toString();


const handleResponse = async (response: Response) => {
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error fetching data: ${response.statusText} - ${errorText}`);
    }
    return await response.json();
};

// Login API
export const login = async (model: { email: string; password: string }) => {

       console.log("user name",model.email);
       console.log("password", model.password);

    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(model),
        });

        if (!response.ok) {
            const errorText = await response.text();
            const errorMessage = `Error ${response.status}: ${errorText || response.statusText}`;
            console.error('Response not OK:', response.statusText, errorText);
            throw new Error(errorMessage);
        } else {
            const data = await response.json();
            console.log('Login successful:', data);           
            // Save token and expiration time
            localStorage.setItem('authToken', data.token);
            localStorage.setItem('tokenExpiration', data.expiration);
            localStorage.setItem('userName', data.userName);
            localStorage.setItem('userId', data.userId);
            sessionStorage.setItem('FirstName', data.firstName);
            sessionStorage.setItem('LastName', data.lastName);
            return data;
        }
    } catch (error) {
        if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
            console.error('Network error or API endpoint is unreachable:', error);
            throw new Error('Unable to connect to the server. Please try again later.');
        } else {
            console.error('Error during login:', error);
            throw error;
        }
    }
};


// Register API
export const register = async (model: {
    Email: string;
    Password: string;
    FirstName: string;
    LastName: string;
  }) => {
    try {
      const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/Register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(model),
      });
  
      if (!response.ok) {
        const errorJson = await response.json();
        throw new Error(errorJson.message || "An error occurred during registration");
      }
      

      const data = await response.json();

       debugger;
       console.log('Registration successful:', data);
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('tokenExpiration', data.expiration);
      localStorage.setItem('userName', data.userName);
      localStorage.setItem('userId', data.userId);
      sessionStorage.setItem('FirstName', data.firstName);
      sessionStorage.setItem('LastName', data.lastName);

      return data;
    } catch (error) {
      console.error("Error during registration:", error);
      throw error;
    }
  };
  

// Forgot Password API
export const forgotPassword = async (email: string) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/ForgotPasswordWithCode`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ Email: email }),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Response not OK:', response.statusText, errorText);
            throw new Error(`Error sending forgot password email: ${response.statusText}`);
        } else {
            console.log('Forgot password email sent successfully');
            return true;
        }
    } catch (error) {
        console.error('Error during forgot password:', error);
        throw error;
    }
};


// Reset Password API
export const resetPassword = async (email: string, code: string, newPassword: string): Promise<boolean> => {
    try {
      console.log("Sending reset password request:", { email, code, newPassword });
  
      const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/ResetPasswordWithCode`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          code,
          newPassword
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Response not OK:', errorData);
        throw new Error(`Error ${response.status}: ${errorData.message}`);
      }
  
      return true;
    } catch (error) {
      console.error('Error during reset password:', error);
      throw error;
    }
  };
  
  

// Change Password API
export const changePassword = async (model: { OldPassword: string; NewPassword: string }) => {
    const authToken = localStorage.getItem('authToken'); // Get the token from local storage
    if (!authToken) {
        throw new Error('No authentication token found');
    }

    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/ChangePassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`, // Include the token in the header
            },
            body: JSON.stringify(model),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error('Response not OK:', response.statusText, errorText);
            throw new Error(`Error changing password: ${errorText || response.statusText}`);
        } else {
            console.log('Password changed successfully');
            return true;
        }
    } catch (error) {
        console.error('Error during change password:', error);
        throw error;
    }
};

// api.ts
export const getCountryList = async (countryId: number) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Country/GetAllCountries?countryId=${countryId}`);
        if (!response.ok) {
            console.error('Response not OK:', response.statusText);
            return [];
        }
        return await response.json(); // Parse JSON
    } catch (error) {
        console.error('Error fetching data:', error);
        return []; // Return empty array on error
    }
};


// api/users.ts
export interface GetAllUsersParams {
    IsActive?: boolean;
    CountryId?: string;
    IsEmailConfirmed?: boolean;
    Role?: string;
    SearchTerm?: string;
    pageNumber?: number;
    pageSize?: number;
    
}

// api/users.ts
export const getAllUsers = async (params: GetAllUsersParams) => {
    try {
        const queryString = new URLSearchParams({
            IsActive: params.IsActive?.toString() || 'true',
            CountryId: params.CountryId || '',
            IsEmailConfirmed: params.IsEmailConfirmed?.toString() || 'false',
            Role: params.Role || '',
            SearchTerm: params.SearchTerm || '',
            pageNumber: params.pageNumber?.toString() || '1', // Default to page 1
            pageSize: params.pageSize?.toString() || '10', // Default to page size 10
        }).toString();

        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Authenticate/GetAllUsers?${queryString}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            console.error('Response not OK:', response.statusText);
            const errorBody = await response.text();
            console.error('Error response body:', errorBody);
            return { users: [], totalRows: 0 }; // Return empty array and 0 totalRows on error
        }

        const data = await response.json();
        console.log('Fetched data:', data); // Log the entire response data

        return {
            users: data.users || [], // Adjust based on your actual API response structure
            totalRows: data.totalRows || 0, // Ensure totalRows is included in the API response
        };
    } catch (error) {
        console.error('Error fetching data:', error);
        return { users: [], totalRows: 0 }; // Return empty array and 0 totalRows on error
    }
};

export const logout = () => {
    debugger;
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiration');
    sessionStorage.removeItem('selectedBranchId');
    window.location.href = '/'; // Redirect to login page
};
