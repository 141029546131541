import apiBaseUrl from '../ApiBaseUrl';
var token = localStorage.getItem('authToken')?.toString();

export const GetChoicesGroupsByRestaurant = async (restaurant_id: number) => {
    if (!token) {
      throw new Error("No authentication token found. Please log in.");
    }
  
    const apiUrl = `${apiBaseUrl.apiBaseUrl}/ChoicesAddsOnsGroup/GetGroupsWithToppingsByRestaurantId/${restaurant_id}`;
    console.log('Request URL:', apiUrl);
  
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);
  
      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);
  
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log('Fetched order details:', data);
  
        return data;  // Returning the fetched order details
      } else {
        const responseBody = await response.text();
        console.error('Unexpected response format:', responseBody);
        throw new Error("Unexpected response format: Expected JSON");
      }
    } catch (error) {
      console.error('Error fetching Choices & Addons:', error);
      return null; // Return null on error
    }
  };

export const AddChoicesAddsOnsGroup = async (groupData: {
    choicesAddsOnsGroupId: number;
    restaurantId: number;
    groupName: string;
    isOptional: boolean;
    allowAddingMultipleTimes: boolean;
    active: boolean;
    forceMin: number;
    forecMax: number;
  }) => {
    if (!token) {
      throw new Error("No authentication token found. Please log in.");
    }
  
    const apiUrl = `${apiBaseUrl.apiBaseUrl}/ChoicesAddsOnsGroup/Add`;
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(groupData),
      });
  
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);
  
      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);
  
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log('Added Choices & Addons group:', data);
  
        return data; // Returning the response from the server
      } else {
        const responseBody = await response.text();
        console.error('Unexpected response format:', responseBody);
        throw new Error("Unexpected response format: Expected JSON");
      }
    } catch (error) {
      console.error('Error adding Choices & Addons group:', error);
      return null; // Return null on error
    }
  };

export const AddTopping = async (toppingData: {
    toppingName: string;
    toppingPrice: number;
    restaurant_id: number;
    choicesAddsOnsGroupId: number;
  }) => {
    if (!token) {
      throw new Error("No authentication token found. Please log in.");
    }
  
    const apiUrl = `${apiBaseUrl.apiBaseUrl}/Toppings/Add`;
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(toppingData),
      });
  
      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);
  
      const contentType = response.headers.get('Content-Type');
      console.log('Content-Type:', contentType);
  
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        console.log('Added Topping:', data);
  
        return data; // Returning the response from the server
      } else {
        const responseBody = await response.text();
        console.error('Unexpected response format:', responseBody);
        throw new Error("Unexpected response format: Expected JSON");
      }
    } catch (error) {
      console.error('Error adding topping:', error);
      return null; // Return null on error
    }
  };