import apiBaseUrl from '../ApiBaseUrl';

export const GetKitchenDisplayOrdersByStatus = async (status: number) => {
  const token = localStorage.getItem('authToken')?.toString(); // Fetch the token inside the function

  if (!token) {
    throw new Error("No authentication token found. Please log in.");
  }

  const apiUrl = `${apiBaseUrl.apiBaseUrl}/KitchenDisplay/GetOrdersByStatus?status=${status}`;
  console.log('Request URL:', apiUrl);

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // Use the fetched token here
        'Content-Type': 'application/json',
      },
    });

    console.log('Response status:', response.status);
    console.log('Response headers:', response.headers);

    const contentType = response.headers.get('Content-Type');
    console.log('Content-Type:', contentType);

    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      console.log('Fetched order details:', data);
      return data; // Return the fetched order details
    } else {
      const responseBody = await response.text();
      console.error('Unexpected response format:', responseBody);
      throw new Error("Unexpected response format: Expected JSON");
    }
  } catch (error) {
    console.error('Error fetching order details:', error);
    return null; // Return null on error
  }
};
