import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css"; // Import Mapbox CSS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"; // Correct import
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { Alert } from "@aws-amplify/ui-react"; // Import Alert component from AWS Amplify UI
import ReactDOMServer from "react-dom/server";
import { registerRestaurant } from "../../Common/SharedApis/MyAllResutrant"; // Import the API method
import "./Branches.css"; // Ensure this file contains relevant CSS styles
import { useNavigate } from "react-router-dom";
import WaitMessage from "../AuthRestaurant/RegisteredRestaurantsScreen/WaitMessage";

mapboxgl.accessToken = "pk.eyJ1IjoidGhlbWV0YXN1bSIsImEiOiJjbTFiczFtMmQwZHdnMmxzNmRsZ292d3hiIn0.XvXHgjczGqyH7DPS8aeN_A"; // Replace with your Mapbox access token

interface FormData {
  restaurantName: string;
  restaurantAddress: string;
  latitude: number | null;
  longitude: number | null;
}

const StepForm: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const navigate = useNavigate();
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [formData, setFormData] = useState<FormData>({
    restaurantName: "",
    restaurantAddress: "",
    latitude: null,
    longitude: null,
  });
  useEffect(() => {
    if (currentStep === 4) {
      const timer = setTimeout(() => {
        navigate("/Allresturents");
      }, 3000); // 3 seconds delay

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [currentStep, navigate]);
  const [loading, setLoading] = useState(false); // Loading state for API call
  const [error, setError] = useState<string | null>(null); // Error handling
  
  const [errors, setErrors] = useState<{ restaurantName: string; restaurantAddress: string }>({
    restaurantName: "",
    restaurantAddress: "",
  });
  
  const [success, setSuccess] = useState<string | null>(null); // Success message

  // Functions to handle form data
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Validation logic
  const validateForm = () => {
    const newErrors = { restaurantName: "", restaurantAddress: "" };
    if (!formData.restaurantName) {
      newErrors.restaurantName = "Restaurant name is required";
    }
    if (!formData.restaurantAddress) {
      newErrors.restaurantAddress = "Restaurant address is required";
    }
    setErrors(newErrors);

    // Return true if there are no errors
    return !newErrors.restaurantName && !newErrors.restaurantAddress;
  };

  const nextStep = () => {
    if(validateForm()) {
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1);
      }
    }
  };
  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Initialize Mapbox Map
  useEffect(() => {
    if (currentStep === 2 && mapContainerRef.current) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current as HTMLDivElement,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0, 20],
        zoom: 1,
        attributionControl: false,
      });

      map.addControl(new mapboxgl.NavigationControl());

      // Get user's current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          map.setCenter([longitude, latitude]); // Set map center to current location
          map.setZoom(12); // Adjust zoom level as needed
          marker.setLngLat([longitude, latitude]); // Set marker position to current location
          setFormData((prevData) => ({
            ...prevData,
            latitude,
            longitude,
          }));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );

      const pinIconContainer = document.createElement("div");
      pinIconContainer.className = "mapboxgl-marker-icon";

      const pinIcon = (
        <FontAwesomeIcon
          icon={faMapPin}
          style={{ fontSize: "30px", color: "red", cursor: "pointer" }}
        />
      );

      pinIconContainer.innerHTML = ReactDOMServer.renderToString(pinIcon);

      const marker = new mapboxgl.Marker({ element: pinIconContainer })
        .setLngLat([0, 20]) // Default initial position
        .addTo(map);

      const setMarkerPosition = () => {
        const center = map.getCenter();
        marker.setLngLat(center);
        setFormData((prevData) => ({
          ...prevData,
          latitude: center.lat,
          longitude: center.lng,
        }));
      };

      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false,
      });
      map.addControl(geocoder);

      geocoder.on("result", (e) => {
        const { geometry } = e.result;
        const { coordinates } = geometry;
        marker.setLngLat(coordinates);
        setFormData((prevData) => ({
          ...prevData,
          latitude: coordinates[1],
          longitude: coordinates[0],
        }));
      });

      map.on("move", setMarkerPosition);

      return () => {
        map.off("move", setMarkerPosition);
        map.remove();
      };
    }
  }, [currentStep]);

  // Submit form and call registerRestaurant API
  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
  
    try {
      // Retrieve user ID from local storage
      const userId = localStorage.getItem("userId"); // Retrieve the user ID
  
      if (!userId) {
        setError("User not logged in.");
        return;
      }
  
      const storeName = formData.restaurantName;
      const storeType = "1"; // Replace with actual store type
      const cityId = "0"; // Replace with actual city ID
      const storeArea = formData.restaurantAddress;
      const { latitude, longitude } = formData;
  
      if (latitude && longitude) {
        const response = await registerRestaurant(
          userId,
          storeName,
          storeType,
          cityId,
          storeArea,
          latitude,
          longitude
        );
        console.log("Restaurant registered successfully:", response);
  
        // Set success message and move to Step 4
        setSuccess("Restaurant registered successfully!");
        setCurrentStep(4); // Move to Step 4 after successful submission
  
      } else {
        setError("Please select a valid location on the map.");
      }
    } catch (err) {
      setError("Failed to register the restaurant. Please try again.");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };
  


  return (
    <>
      <h1>Create a New Branch</h1>
      <Row>
        <Col
          lg={8}
          style={{
            maxHeight: "100vh",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "15px gray",
            padding: "5%",
            margin: "auto",
          }}
        >
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
  {/* Step 1 */}
  <div
    style={{
      width: "58px",
      height: "40px",
      backgroundColor: currentStep >= 1 ? "#ff5722" : "#ddd",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    1
  </div>
  <div
    style={{
      width: "40%",
      height: "8px",
      margin: "auto 0px",
      backgroundColor: currentStep >= 2 ? "#ff5722" : "#ddd",
    }}
  ></div>

  {/* Step 2 */}
  <div
    style={{
      width: "58px",
      height: "40px",
      backgroundColor: currentStep >= 2 ? "#ff5722" : "#ddd",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    2
  </div>
  <div
    style={{
      width: "40%",
      height: "8px",
      margin: "auto 0px",
      backgroundColor: currentStep >= 3 ? "#ff5722" : "#ddd",
    }}
  ></div>

  {/* Step 3 */}
  <div
    style={{
      width: "58px",
      height: "40px",
      backgroundColor: currentStep >= 3 ? "#ff5722" : "#ddd",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    3
  </div>
  <div
    style={{
      width: "40%",
      height: "8px",
      margin: "auto 0px",
      backgroundColor: currentStep >= 4 ? "#ff5722" : "#ddd",
    }}
  ></div>

  {/* Step 4 */}
  <div
    style={{
      width: "58px",
      height: "40px",
      backgroundColor: currentStep === 4 ? "#ff5722" : "#ddd",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    4
  </div>
</div>


          {currentStep === 1 && (
            <Form>
              <Form.Group controlId="restaurantName">
                <Form.Label>Restaurant Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter restaurant name"
                  name="restaurantName"
                  value={formData.restaurantName}
                  onChange={handleChange}
                  isInvalid={!!errors.restaurantName} // Error state
                />
                {errors.restaurantName && (
                  <div className="text-danger">{errors.restaurantName}</div> // Error message in red
                )}
              </Form.Group>

              <Form.Group controlId="restaurantAddress">
                <Form.Label>Restaurant Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter restaurant address"
                  name="restaurantAddress"
                  value={formData.restaurantAddress}
                  onChange={handleChange}
                  isInvalid={!!errors.restaurantAddress} // Error state
                />
                 {errors.restaurantAddress && (
                  <div className="text-danger">{errors.restaurantAddress}</div> // Error message in red
                )}
              </Form.Group>
              <Button variant="primary" onClick={nextStep} style={{ marginTop: "20px", display:"flex-end" }}>
                Next
              </Button>
            </Form>
          )}

{currentStep === 2 && (
<>
  <div ref={mapContainerRef} style={{ height: "400px", marginTop: "20px" }} />
  <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
  <Button variant="secondary" onClick={prevStep}>
      Back
  </Button>
  <Button variant="primary" onClick={nextStep}>
      Next
  </Button>
  </div>
</>
 )}

          {currentStep === 3 && (
            <>
              <h2>Confirm Details</h2>
              <p>
                <strong>Restaurant Name:</strong> {formData.restaurantName}
              </p>
              <p>
                <strong>Restaurant Address:</strong> {formData.restaurantAddress}
              </p>
              <p>
                <strong>Latitude:</strong> {formData.latitude}
              </p>
              <p>
                <strong>Longitude:</strong> {formData.longitude}
              </p>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                <Button variant="secondary" onClick={prevStep}>
                  Back
                </Button>
                <Button
                  variant="success"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </div>
              

              {error && (
            <Alert variation="error" isDismissible className="alert-margin-top">
              {error}
            </Alert>
          )}
          {success && (
            <Alert variation="success" isDismissible className="alert-margin-top">
              {success}
            </Alert>
          )}
            </>
          )}
           {currentStep === 4 && (
            <WaitMessage />
          )}
        </Col>
      </Row>
    </>
  );
};

export default StepForm;
