import React from "react";
import './DashboardTiles.css';

const DashboardCard = ({ title, count, formattedPercentageChange, arrowDirection, icon }: IDashboardOrderTiles) => {
  return (
       
    <div className="dashboard-card">
      <div className="card-content">
        <div className="icon-wrapper">
          <img src={icon} alt={`${title} Icon`} className="icon-image" />          
          {/* <img src='../../../Icon_Order.png' alt="Order Icon" className="icon-image" />           */}
        </div>
        <div className="text-content">
          <div className="card-number">{count}</div>
          <div className="card-text">{title}</div>
          <div className={`card-percentage ${arrowDirection}`}>
          <img 
          src={arrowDirection === 'up' ? '../../../Upward.png' : '../../../Downward.png'} 
          alt={`${arrowDirection === 'up' ? 'Up' : 'Down'} Arrow`} 
          className={`${arrowDirection}-arrow-icon`}
          style={{ width: '15%', height: '15%' }}
           />
            <span style={{ whiteSpace: 'nowrap' }}>{formattedPercentageChange} (30 days)</span>
          </div>
        </div>
      </div>
    </div>

  );
};

export default DashboardCard;
