import React, { useState, useEffect } from "react";
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import { MdOutlineSettings } from "react-icons/md"; // Import the settings icon

import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { ThemeProvider } from "@aws-amplify/ui-react";
import theme from "./theme";

import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import Forms from "./pages/forms";
import EditForm from "./pages/forms/EditForm";
import Menue from "./pages/Menue/Menue";
import Orderlist from "./pages/Orderlist/OrderlistPage";
import Item from "./pages/Items/ItemPage";
import StatusBar from "./context/StatusBar";
import { SignalRProvider } from "./context/SignalRProvider";
import ErrorBoundary from "./context/ErrorBoundary";
import useOnlineStatus from "./context/useOnlineStatus";
import LoginPage from "./pages/Auth/Login/Loginpage";

import DeliveryAreaMap from "./pages/DeliveryMap/DeliveryAreaMap";
import CategoryPage from "./pages/CategoryGrid/categorygrid";
import Layout from "../src/components/Layout";
import NewOrderDetails from "./pages/NewOrderDetails/NewOrderDetails";

import ResetPasswordPage from "./pages/Auth/ResetPasswordPage/ResetPasswordPage";
import CreateResturant from "./pages/AuthRestaurant/NewRestaurant/CreateRestaurantPage";
import Location from "./pages/AuthRestaurant/NewRestaurant/Location";

import GoToTopButton from './components/GotoTop/GotoTop';
import MainPOS from "./pages/POS/MainPOS";
import CustomerListPage from "./pages/Customer/CustomerListPage";
import KitchenDisplay from "./pages/Kitchen/kitchenDisplay";
import MainDashboard from './pages/Reports/mainDashboard';
import { ToastContainer } from "react-toastify";
import MusicPage from "./pages/Notification Sounds/MusicPage";
import ProtectedRoute from "./context/ProtectedRoute";
import { AudioProvider } from './context/AudioContext';
import EnableNotificationButton from "./context/EnableNotificationButton";
import ThreeStepForm from "./pages/Branches/BranchesPractice/BranchesPractice";
import ChatSidebar from "./pages/Chat/Chat";
import CustomerDetails from "./pages/Customer/customerDetails";
import StepForm from "./pages/Branches/Branches";
import OrderTracking from "./pages/TrackOrder/Trackorder";
import OrderTrackingleaflet from "./pages/TrackOrder/ordertracking";
import SettingsModal from './pages/Settings/SettingsModal'; // Import the Settings modal
import DisplayItemsMenu from "./pages/Items/DisplayItemsMenu";
import BranchGuard from "./context/BranchGuard";
import SignUpPage from "./pages/Auth/SignUp/SignUpPage";
import ForgotPasswordPage from "./pages/Auth/ForgotPasswordPage/ForgotPasswordPage";
import WAITINGSCREEN from "./pages/AuthRestaurant/RegisteredRestaurantsScreen/RegisteredRestaurantsScreen";
import Allresturents from "./pages/AuthRestaurant/RegisteredRestaurantsScreen/MyAllResturants";
import WaitMessage from "./pages/AuthRestaurant/RegisteredRestaurantsScreen/WaitMessage";

export default function App() {
  const navigate = useNavigate(); // React Router hook for navigation
  const location = useLocation(); // React Router hook for location tracking
  const isOnline = useOnlineStatus();  // Custom hook to check online status
  const [showSettingsModal, setShowSettingsModal] = useState(false);  // Modal state
  const [showStatusBar, setShowStatusBar] = useState(false);
  const [statusMessage, setStatusMessage] = useState('No internet connection detected. Please check your network settings.');

  // Function to handle the "Settings" button click
  const handleSettingsClick = () => {
    setShowSettingsModal(true);  // Open modal
  };

  // Function to close the settings modal
  const handleCloseSettingsModal = () => {
    setShowSettingsModal(false);  // Close modal
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* Status Bar */}

        {/* Settings Button */}

        {/* Settings Modal */}
        {showSettingsModal && <SettingsModal show={showSettingsModal} handleClose={handleCloseSettingsModal} />}

        {/* Application Routes */}
        <AudioProvider>
          <SignalRProvider>
            <ErrorBoundary>
              <ToastContainer />
              <EnableNotificationButton />
              
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route path="/create-resturant" element={<CreateResturant />} />
                <Route path="/location" element={<Location />} />
                <Route path="/waiting-screen" element={<WAITINGSCREEN />} />
                <Route path="myAllBranches" element={<Allresturents />} />
             
                {/* Removed settings route here */}
                <Route element={<ProtectedRoute />}>
                <Route element={<BranchGuard />}>
                  <Route path="/" element={<Layout />}>       
                    <Route path="forms" element={<Forms />} />
                    <Route path="edit-form" element={<EditForm />} />
                    <Route path="dashboard" element={<Dashboard />} />
             
                    <Route path="ordertrackingmapbox" element={<OrderTracking />} />
                    <Route path="ordertrackingleaflet" element={<OrderTrackingleaflet />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="Menue" element={<Menue />} />
                    <Route path="Orderlist" element={<Orderlist />} />
                    <Route path="Items" element={<Item />} />
                    <Route path="/music" element={<MusicPage />} />
                    <Route path="allCategory" element={<CategoryPage />} />
                    <Route path="myAllBranches" element={<Allresturents />} />
                    <Route path="DeliveryAreaMap" element={<DeliveryAreaMap />} />
                    <Route path="/settings" element={<SettingsModal show={true} handleClose={handleCloseSettingsModal} />} />
                    <Route path="NewOrderDetails/:order_id" element={<NewOrderDetails />} />
                    <Route path="neworder" element={<NewOrderDetails />} />
                    <Route path="allCustomers" element={<CustomerListPage />} />
                    <Route path="mainPOS" element={<MainPOS />} />
                    <Route path="kitchenDisplay" element={<KitchenDisplay />} />
                    <Route path="mainDashboard" element={<MainDashboard />} />
                    <Route path="*" element={<NoMatch />} />
                    <Route path="BranchesPractice" element={<ThreeStepForm />} />
                    <Route path="Chat" element={<ChatSidebar />} />
                    <Route path="customerDetails" element={<CustomerDetails />} />
                    <Route path="WaitMessage" element={<WaitMessage />} />
                    <Route path="/DisplayItemsMenu" element={<DisplayItemsMenu />} />
                    <Route path="branches" element={<StepForm />} />
                  </Route>
                  </Route>
                </Route>
              </Routes>
            </ErrorBoundary>
          </SignalRProvider>
        </AudioProvider>

        {/* Go to Top Button */}
        <GoToTopButton />
      </div>
    </ThemeProvider>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p><Link to="/">Go to the home page</Link></p>
    </div>
  );
}
