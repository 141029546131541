import apiBaseUrl from '../ApiBaseUrl';
var token = localStorage.getItem('authToken')?.toString();


const handleResponse = async (response: Response) => {
    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error fetching data: ${response.statusText} - ${errorText}`);
    }
    return await response.json();
};




export const getCountryList = async (countryId: number) => {
    try {
        const response = await fetch(`${apiBaseUrl.apiBaseUrl}/Country/GetAllCountries`);
        if (!response.ok) {
            console.error('Response not OK:', response.statusText);
            return [];
        }
        return await response.json(); // Parse JSON
    } catch (error) {
        console.error('Error fetching data:', error);
        return []; // Return empty array on error
    }
};