import React from 'react';
import { Container, ListGroup, InputGroup, FormControl, Button, Row, Col } from 'react-bootstrap';

const ChatLeftSide: React.FC = () => {
  return (
    <Container fluid style={{ maxWidth: '318px',  padding: '20px',  position: 'fixed', left: '17%', top: 0, height: '100vh', overflowY: 'auto', margin:'40px', marginTop: '60px', backgroundColor: 'white', borderRadius: '20px'}}
    className="chat-left-side"
    >
     
      {/* Search and Plus Button */}
      <Row className="mb-4">
        <Col>
          <InputGroup>
            <FormControl
              placeholder="Search here..."
              aria-label="Search"
              style={{
                borderRadius: '10px',
                backgroundColor: '#f5f7fb',
                border: 'none',
                boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
              }}
            />
            <Button variant="success" style={{ borderRadius: '10px', marginLeft: '10px' }}>
              +
            </Button>
          </InputGroup>
        </Col>
      </Row>

      {/* Pinned Messages */}
      <div className="mb-3">
        <h6>Pinned Message</h6>
        <ListGroup variant="flush">
          <ListGroup.Item style={{ display: 'flex', alignItems: 'center', border: 'none' }}>
            <img
              src="https://via.placeholder.com/40"
              alt="avatar"
              className="rounded-circle"
              style={{ marginRight: '10px' }}
            />
            <div style={{ flexGrow: 1 }}>
              <div style={{ fontWeight: 'bold' }}>Robert Johanson</div>
              <div>Hi David, can you send your...</div>
            </div>
            <div style={{ textAlign: 'right', color: '#aaa', fontSize: '12px' }}>2 min ago</div>
            <div style={{ backgroundColor: '#007bff', color: '#fff', borderRadius: '12px', padding: '2px 8px', marginLeft: '10px' }}>
              2
            </div>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: 'flex', alignItems: 'center', border: 'none' }}>
            <img
              src="https://via.placeholder.com/40"
              alt="avatar"
              className="rounded-circle"
              style={{ marginRight: '10px' }}
            />
            <div style={{ flexGrow: 1 }}>
              <div style={{ fontWeight: 'bold' }}>Chloe Jess</div>
              <div>I have done my task last week...</div>
            </div>
            <div style={{ textAlign: 'right', color: '#aaa', fontSize: '12px' }}>15 min ago</div>
            <div style={{ color: '#28a745', fontWeight: 'bold', marginLeft: '10px' }}>✔</div>
          </ListGroup.Item>
        </ListGroup>
      </div>

      {/* Recent Messages */}
      <div>
        <h6>Recent Message</h6>
        <ListGroup variant="flush">
          <ListGroup.Item style={{ display: 'flex', alignItems: 'center', border: 'none' }}>
            <img
              src="https://via.placeholder.com/40"
              alt="avatar"
              className="rounded-circle"
              style={{ marginRight: '10px' }}
            />
            <div style={{ flexGrow: 1 }}>
              <div style={{ fontWeight: 'bold' }}>Roberto</div>
              <div>Last week, do you remember?</div>
            </div>
            <div style={{ textAlign: 'right', color: '#aaa', fontSize: '12px' }}>02:45 AM</div>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: 'flex', alignItems: 'center', border: 'none' }}>
            <img
              src="https://via.placeholder.com/40"
              alt="avatar"
              className="rounded-circle"
              style={{ marginRight: '10px' }}
            />
            <div style={{ flexGrow: 1 }}>
              <div style={{ fontWeight: 'bold' }}>Lisa Blekcurent</div>
              <div>My boss gave me that task last...</div>
            </div>
            <div style={{ textAlign: 'right', color: '#aaa', fontSize: '12px' }}>2 min ago</div>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: 'flex', alignItems: 'center', border: 'none' }}>
            <img
              src="https://via.placeholder.com/40"
              alt="avatar"
              className="rounded-circle"
              style={{ marginRight: '10px' }}
            />
            <div style={{ flexGrow: 1 }}>
              <div style={{ fontWeight: 'bold' }}>Olivia Braun</div>
              <div>Hei, you forgot to upload submi...</div>
            </div>
            <div style={{ textAlign: 'right', color: '#aaa', fontSize: '12px' }}>Yesterday</div>
          </ListGroup.Item>

          <ListGroup.Item style={{ display: 'flex', alignItems: 'center', border: 'none' }}>
            <img
              src="https://via.placeholder.com/40"
              alt="avatar"
              className="rounded-circle"
              style={{ marginRight: '10px' }}
            />
            <div style={{ flexGrow: 1 }}>
              <div style={{ fontWeight: 'bold' }}>James Sudayat</div>
              <div>Hi David, can you send your...</div>
            </div>
            <div style={{ textAlign: 'right', color: '#aaa', fontSize: '12px' }}>Yesterday</div>
          </ListGroup.Item>
        </ListGroup>
      </div>  
   </Container>
  );
};

export default ChatLeftSide;
