import React from "react";
const WaitMessage: React.FC = () => {
  return (
    <div className="wait-message">
      <h2>Please wait...</h2>
      <p>Your restaurant will be added in a moment.</p>
    </div>
  );
};

export default WaitMessage;
