import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon from MUI
import { GetItemWithChoicesAndToppingsById } from '../../Common/SharedApis/Item';

interface Topping {
  toppingId: number;
  toppingName: string;
  toppingPrice: number;
  preSelected: boolean | null;
}

interface ChoicesAddsOnsGroup {
  choicesAddsOnsGroupId: number;
  groupName: string;
  isOptional: boolean;
  allowAddingMultipleTimes: boolean;
  forceMin: number;
  forceMax: number;
  active: boolean;
  toppings: Topping[];
}

interface Variant {
  variantName: string;
  variantPrice: number;
  choicesAddsOnsGroupList: ChoicesAddsOnsGroup[];
}

interface ItemData {
  item_id: number;
  item_barCode: string | null;
  item_name: string;
  item_price: number;
  item_description: string;
  isAvaliableOnline: boolean;
  cat_id: number;
  restaurant_id: number;
  imageFile: string | null;
  isVarient: boolean;
  isDeal: boolean;
  hasToppings: boolean;
  variants: Variant[];
  itemTopings: null;
  choicesAddsOnsGroupList: ChoicesAddsOnsGroup[];
}

const ItemDetail = ({ itemId, open, onClose }) => {
  const [itemData, setItemData] = useState<ItemData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedToppings, setSelectedToppings] = useState<{ [key: number]: number[] }>({});
  const [minSelectionErrors, setMinSelectionErrors] = useState<{ [key: number]: string }>({});
  const [maxSelectionErrors, setMaxSelectionErrors] = useState<{ [key: number]: string }>({});
  const [popupClosed, setPopupClosed] = useState(false); // To track if the popup was closed

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const data = await GetItemWithChoicesAndToppingsById(itemId);
        setItemData(data);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError('An unknown error occurred');
        }
        setLoading(false);
      }
    };

    fetchItemData();
  }, [itemId]);

  const handleCheckboxChange = (groupId: number, toppingId: number) => {
    setSelectedToppings((prevSelected) => {
      const groupToppings = prevSelected[groupId] || [];
      const updatedToppings = groupToppings.includes(toppingId)
        ? groupToppings.filter((id) => id !== toppingId) // Unselect topping
        : [...groupToppings, toppingId]; // Select topping

      const updatedSelections = { ...prevSelected, [groupId]: updatedToppings };

      // If the group is optional (multiple selections allowed), validate selections
      if (itemData?.choicesAddsOnsGroupList.find((group) => group.choicesAddsOnsGroupId === groupId)?.isOptional) {
        validateSelections(updatedSelections, false); // Validate without showing min errors during interaction
      }

      return updatedSelections;
    });
  };

  const handleRadioChange = (groupId: number, toppingId: number) => {
    setSelectedToppings((prevSelected) => {
      const updatedSelections = {
        ...prevSelected,
        [groupId]: [toppingId], // Only one topping is allowed when isOptional is false
      };

      // Validate selections for groups that are not optional (force exactly one topping)
      if (!itemData?.choicesAddsOnsGroupList.find((group) => group.choicesAddsOnsGroupId === groupId)?.isOptional) {
        validateSelections(updatedSelections, false); // Same logic as checkboxes
      }

      return updatedSelections;
    });
  };

  const validateSelections = (updatedSelections, isClosing: boolean) => {
    const newMinErrors: { [key: number]: string } = {};
    const newMaxErrors: { [key: number]: string } = {};

    itemData?.choicesAddsOnsGroupList.forEach((group) => {
      const selectedCount = updatedSelections[group.choicesAddsOnsGroupId]?.length || 0;

      // Handle min errors (only show when closing the popup)
      if (selectedCount < group.forceMin) {
          newMinErrors[group.choicesAddsOnsGroupId] = `Please select at least ${group.forceMin} topping(s).`;
      }

      // Handle max errors (show immediately when exceeded)
      if (selectedCount > group.forceMax) {
        newMaxErrors[group.choicesAddsOnsGroupId] = `Please select no more than ${group.forceMax} topping(s).`;
      }
    });

    setMinSelectionErrors(newMinErrors);
    setMaxSelectionErrors(newMaxErrors);
  };

  const handleClose = () => {

    setPopupClosed(true); // Set the state to true when the popup is closed
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Item Details
        <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">Error: {error}</Typography>
        ) : !itemData ? (
          <Typography>No data found.</Typography>
        ) : (
          <>
            <Typography variant="h5">{itemData.item_name}</Typography>
            <Typography>Description: {itemData.item_description}</Typography>
            <Typography>Price: ${itemData.item_price}</Typography>
            <Typography>Available Online: {itemData.isAvaliableOnline ? 'Yes' : 'No'}</Typography>

            {/* Variants Section */}
            {itemData.isVarient && (
              <div className="variants">
                <Typography variant="h6">Variants:</Typography>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Choose a variant:</FormLabel>
                  <RadioGroup
                    value={selectedToppings['variant'] || ''}
                    onChange={(e) =>
                      setSelectedToppings((prev) => ({
                        ...prev,
                        variant: e.target.value,
                      }))
                    }
                  >
                    {itemData.variants.map((variant, index) => (
                      <FormControlLabel
                        key={index}
                        value={variant.variantName}
                        control={<Radio />}
                        label={`${variant.variantName} - $${variant.variantPrice.toFixed(2)}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            )}

            {/* Add-ons Section */}
            {itemData.choicesAddsOnsGroupList && itemData.choicesAddsOnsGroupList.length > 0 && (
              <div className="addons">
                <Typography variant="h6">Available Add-ons:</Typography>

                {/* Show max selection errors immediately */}
                {Object.keys(maxSelectionErrors).length > 0 && (
                  <div>
                    {Object.entries(maxSelectionErrors).map(([groupId, errorMessage]) => (
                      <Typography key={groupId} color="error">
                        {errorMessage}
                      </Typography>
                    ))}
                  </div>
                )}

                {/* Show min selection errors only when the popup is closed */}
                {popupClosed && Object.keys(minSelectionErrors).length > 0 && (
                  <div>
                    {Object.entries(minSelectionErrors).map(([groupId, errorMessage]) => (
                      <Typography key={groupId} color="error">
                        {errorMessage}
                      </Typography>
                    ))}
                  </div>
                )}

                {/* Add-ons group */}
                {itemData.choicesAddsOnsGroupList.map((group, index) => (
                  <div key={index} className="addon-group">
                    <Typography variant="subtitle1">
                      {group.groupName} (Min: {group.forceMin}, Max: {group.forceMax})
                    </Typography>

                    {/* Add toppings based on isOptional */}
                    {group.isOptional ? (
                      <FormGroup>
                        {group.toppings.map((topping) => (
                          <FormControlLabel
                            key={topping.toppingId}
                            control={
                              <Checkbox
                                checked={selectedToppings[group.choicesAddsOnsGroupId]?.includes(topping.toppingId) || false}
                                onChange={() => handleCheckboxChange(group.choicesAddsOnsGroupId, topping.toppingId)}
                              />
                            }
                            label={`${topping.toppingName} - $${topping.toppingPrice.toFixed(2)}`}
                          />
                        ))}
                      </FormGroup>
                    ) : (
                      <FormControl>
                        <FormLabel component="legend">{group.groupName}</FormLabel>
                        <RadioGroup
                          value={selectedToppings[group.choicesAddsOnsGroupId]?.[0] || ''}
                          onChange={(e) => handleRadioChange(group.choicesAddsOnsGroupId, Number(e.target.value))}
                        >
                          {group.toppings.map((topping) => (
                            <FormControlLabel
                              key={topping.toppingId}
                              value={topping.toppingId.toString()} // Convert to string for RadioGroup value
                              control={<Radio />}
                              label={`${topping.toppingName} - $${topping.toppingPrice.toFixed(2)}`}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                    {/*Show error message for more than 1 selection */}
    {selectedToppings[group.choicesAddsOnsGroupId]?.length > 1 && (
      <Typography color="error">
        Please select no more than one option.
      </Typography>
    )}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemDetail;
