import React, { useState } from 'react';
import { Modal, Tab, Nav, Button, Form, Row, Col } from 'react-bootstrap';
import { FaCog, FaPalette, FaMicrophone, FaDatabase, FaUserTie, FaLock } from 'react-icons/fa';
import './SettingsModal.css'; // Import custom styles

const SettingsModal = ({ show, handleClose }) => {
  const [activeKey, setActiveKey] = useState('general');  // Track active tab
  const [settings, setSettings] = useState({
    theme: 'System',
    language: 'Auto-detect',
    showCode: true,
    displayDensity: 'Comfortable',
    speechRate: 1,
    speechLanguage: 'Auto-detect',
    profileName: '',
    profileVisibility: 'Public',
    newPassword: '',
    twoFactorAuth: true,
  });

  // Handle tab selection
  const handleSelect = (selectedKey) => {
    if (selectedKey !== null) {
      setActiveKey(selectedKey);
    }
  };

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings({
      ...settings,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Handle modal close
  const closeModal = () => {
    setActiveKey('general'); // Reset to default tab when closed
    handleClose(); // Close the modal
  };

  // Handle saving settings
  const handleSaveChanges = () => {
    // Add your logic here to save the settings
    console.log('Saved Settings:', settings);
    closeModal(); // Close the modal after saving
  };

  return (
    <Modal show={show} onHide={closeModal} centered size="lg" className="settings-modal-custom">
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
          <Row>
            {/* Sidebar Navigation with Icons */}
            <Col md={4} className="border-right">
              <Nav variant="pills" className="flex-column nav-pills-sidebar">
                <Nav.Item>
                  <Nav.Link eventKey="general" className="custom-tab">
                    <FaCog className="tab-icon" /> General
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="personalization" className="custom-tab">
                    <FaPalette className="tab-icon" /> Personalization
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="speech" className="custom-tab">
                    <FaMicrophone className="tab-icon" /> Speech
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="dataControls" className="custom-tab">
                    <FaDatabase className="tab-icon" /> Data Controls
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="builderProfile" className="custom-tab">
                    <FaUserTie className="tab-icon" /> Builder Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="security" className="custom-tab">
                    <FaLock className="tab-icon" /> Security
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>

            {/* Tab Content */}
            <Col md={8}>
              <Tab.Content>
                <Tab.Pane eventKey="general">
                  <h5 className="tab-title">General Settings</h5>
                  <Form>
                    <Form.Group controlId="theme">
                      <Form.Label>Theme</Form.Label>
                      <Form.Control as="select" name="theme" value={settings.theme} onChange={handleInputChange} className="custom-select">
                        <option>System</option>
                        <option>Dark</option>
                        <option>Light</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="language">
                      <Form.Label>Language</Form.Label>
                      <Form.Control as="select" name="language" value={settings.language} onChange={handleInputChange} className="custom-select">
                        <option>Auto-detect</option>
                        <option>English</option>
                        <option>Spanish</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="personalization">
                  <h5 className="tab-title">Personalization Settings</h5>
                  <Form>
                    <Form.Group controlId="showCode">
                      <Form.Check
                        type="switch"
                        name="showCode"
                        label="Show code analysis"
                        className="custom-switch"
                        checked={settings.showCode}
                        onChange={handleInputChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="displayDensity">
                      <Form.Label>Display Density</Form.Label>
                      <Form.Control as="select" name="displayDensity" value={settings.displayDensity} onChange={handleInputChange} className="custom-select">
                        <option>Comfortable</option>
                        <option>Compact</option>
                        <option>Expanded</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="speech">
                  <h5 className="tab-title">Speech Settings</h5>
                  <Form>
                    <Form.Group controlId="speechRate">
                      <Form.Label>Speech Rate</Form.Label>
                      <Form.Control type="range" name="speechRate" min="0.5" max="2" step="0.1" value={settings.speechRate} onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="speechLanguage">
                      <Form.Label>Speech Language</Form.Label>
                      <Form.Control as="select" name="speechLanguage" value={settings.speechLanguage} onChange={handleInputChange} className="custom-select">
                        <option>Auto-detect</option>
                        <option>English</option>
                        <option>French</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="dataControls">
                  <h5 className="tab-title">Data Controls</h5>
                  <Button variant="primary" className="custom-button">Manage Archived Data</Button>
                </Tab.Pane>

                <Tab.Pane eventKey="builderProfile">
                  <h5 className="tab-title">Builder Profile</h5>
                  <Form>
                    <Form.Group controlId="profileName">
                      <Form.Label>Profile Name</Form.Label>
                      <Form.Control type="text" name="profileName" placeholder="Enter your builder profile name" value={settings.profileName} onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="profileVisibility">
                      <Form.Label>Profile Visibility</Form.Label>
                      <Form.Control as="select" name="profileVisibility" value={settings.profileVisibility} onChange={handleInputChange} className="custom-select">
                        <option>Public</option>
                        <option>Private</option>
                        <option>Friends Only</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="security">
                  <h5 className="tab-title">Security Settings</h5>
                  <Form>
                    <Form.Group controlId="changePassword">
                      <Form.Label>Change Password</Form.Label>
                      <Form.Control type="password" name="newPassword" placeholder="Enter new password" value={settings.newPassword} onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="twoFactorAuth">
                      <Form.Check
                        type="switch"
                        name="twoFactorAuth"
                        label="Enable Two-Factor Authentication"
                        className="custom-switch"
                        checked={settings.twoFactorAuth}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Form>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal} className="custom-button-secondary">
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveChanges} className="custom-button">
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SettingsModal;
