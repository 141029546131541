import React, { useEffect, useState } from "react";
import { Alert, Button, TextField } from "@aws-amplify/ui-react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { getCountryList } from "../../../Common/SharedApis/Country"; // Your generic API

// Your Mapbox Access Token
const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoidGhlbWV0YXN1bSIsImEiOiJjbTFiczFtMmQwZHdnMmxzNmRsZ292d3hiIn0.XvXHgjczGqyH7DPS8aeN_A";

const CreateRestaurant: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    restaurantName: "",
    address: "",
    countryId: "",
  });
  const [countryList, setCountryList] = useState<any[]>([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [businessType, setBusinessType] = useState<string>("RESTAURANT");

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setValues({ ...values, address: query }); // Update the city field value

    if (!values.countryId) {
      setResponseMessage("Please select a country before entering the city.");
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    if (query.length > 2) {
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            query
          )}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
        );
        const data = await response.json();
        const fetchedSuggestions = data.features.map(
          (feature: any) => feature.place_name
        );
        setSuggestions(fetchedSuggestions);
        setShowSuggestions(true);
      } catch (error) {
        console.error("Error fetching city suggestions:", error);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const selectSuggestion = (address: string) => {
    setValues({ ...values, address });
    setShowSuggestions(false);
    setSuggestions([]);
  };

  useEffect(() => {
    const loadCountries = async () => {
      const countries = await getCountryList(0);
      setCountryList(
        countries.map((country) => ({
          label: `${country.name} (${country.code})`,
          value: country.name,
          flagUrl: country.flagUrl,
        }))
      );
    };

    loadCountries();
  }, []);

  const handleCountryChange = (selectedOption: any) => {
    setValues({ ...values, countryId: selectedOption.value });
  };

  const getCoordinatesForCity = async (cityName: string) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          cityName
        )}.json?access_token=${MAPBOX_ACCESS_TOKEN}&limit=1`
      );
      const data = await response.json();
      if (data && data.features && data.features.length > 0) {
        const [longitude, latitude] = data.features[0].center;
        return { longitude, latitude };
      } else {
        throw new Error("Coordinates not found for the selected city.");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const dataToSend = {
      RestaurantName: values.restaurantName,
      Address: values.address,
      CountryId: values.countryId,
    };

    try {
      console.log("Data Submitted:", dataToSend);
      const cityCoords = await getCoordinatesForCity(values.address);

      if (cityCoords) {
        navigate("/location", {
          state: {
            coords: [cityCoords.longitude, cityCoords.latitude],
            restaurantName: values.restaurantName, // Pass restaurant name here
            Address: values.address,
          },
        });
      } else {
        setResponseMessage(
          "Failed to retrieve coordinates for the selected city."
        );
      }
    } catch (error) {
      setResponseMessage(
        error instanceof Error ? error.message : "Unknown error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="main-page">
        <div className="left-section">
          <img
            src="/sumbite_white_logo.png"
            alt="SumBite Logo"
            className="logo"
          />
          <p>Join Us and Stay In Control</p>
          <p>
            Track orders, manage tables, and optimize restaurant operations with
            SumBite.
          </p>
          <div className="social-icons">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </div>
        </div>

        <div className="right-section">
          <form className="login-form" onSubmit={handleSubmit}>
            {/* Display error message as an Alert */}
            {responseMessage && (
              <Alert variation="error" isDismissible={true}>
                {responseMessage}
              </Alert>
            )}
            <h2>Join SumBite</h2>
            <p className="subtitle">
              Let’s set up your restaurant profile to get started.
            </p>

            {/* Country Select */}
            <div className="select-wrapper">
              <label htmlFor="country-select" className="select-label">
                Select Country
              </label>
              <Select
                id="country-select"
                options={countryList}
                onChange={handleCountryChange}
                className="select-field"
                placeholder="Select a country..."
                isSearchable
              />
            </div>

            {/* Restaurant Name */}
            <TextField
              value={values.restaurantName}
              onChange={(e) =>
                setValues({ ...values, restaurantName: e.target.value })
              }
              name="restaurantName"
              label="Restaurant Name"
              type="text"
              width="100%"
              required
              className="margin-top text-field"
              placeholder="Enter your restaurant's name"
            />
            {/* Business Type Radio Buttons */}
            <div>
              <label className="select-label">Business Type</label>
              <div style={{ display: "flex", justifyContent: "space-between",marginTop:"10px" }}>
                <label>
                  <input
                    type="radio"
                    name="businessType"
                    value="RESTAURANT"
                    checked={businessType === "RESTAURANT"}
                    onChange={(e) => setBusinessType(e.target.value)}
                  />
                  Restaurant
                </label>
                <label>
                  <input
                    type="radio"
                    name="businessType"
                    value="MART"
                    checked={businessType === "MART"}
                    onChange={(e) => setBusinessType(e.target.value)}
                  />
                  Mart
                </label>
              </div>
            </div>

            {/* City Auto-complete */}
            <div className="auto-search-mapbox">
              <div className="margin-top select-label">
                <label htmlFor="select-label">Restaurant Address</label>
                <input
                  type="text"
                  id="city-field"
                  value={values.address}
                  onChange={handleInputChange} // Handles autocomplete suggestions
                  placeholder="Enter city where restaurant is located"
                  required
                  className="text-field"
                />
                {/* Display Suggestions */}
                {showSuggestions && suggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onClick={() => selectSuggestion(suggestion)}
                        className="suggestion-item"
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <Button
              type="submit"
              className="margin-top button-style"
              isLoading={isLoading}
            >
              Continue
            </Button>

            <div className="links-container">
              <Link to="/login">Already have an account , Back to login?</Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateRestaurant;
