// EnableNotificationButton.tsx
import React from 'react';
import { useAudio } from './AudioContext';

const EnableNotificationButton: React.FC = () => {
    const { playNotification } = useAudio();

    const handleEnableSound = () => {
        console.log('playNotification');
        // Play a test sound to ensure the user interaction allows playing audio
        playNotification();
    };

    return (
        <div style={{height:'0px',width:'0px'}} onClick={handleEnableSound}>
          
        </div>
    );
};

export default EnableNotificationButton;
