import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './category.css';
import { View } from '@aws-amplify/ui-react';
import { fetchcategory } from '../../Common/SharedApis/Category';
import AddCategoryPopup from './AddCategoryPopup';
import { useNavigate } from 'react-router-dom';

type Category = {
  categoryId: number;
  categoryName: string;
};

const CategoryPage = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [page, setPage] = useState(1);
  const [categoriesPerPage, setCategoriesPerPage] = useState(10);
  const [showAddCategoryPopup, setShowAddCategoryPopup] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const selectedBranchId = Number(sessionStorage.getItem('selectedBranchId'));
  const navigate = useNavigate();

  // Fetch categories
  const fetchCategoryData = async () => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      setLoading(true);
      try {
        const data = await fetchcategory(selectedBranchId);
        const categoriesWithId = data.map((category: any, index: number) => ({
          ...category,
          id: index + 1,
          categoryName: category.categoryName,
        }));
        setCategories(categoriesWithId);
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
    setPage(1);
  };

  const filteredCategories = categories
    .filter((category) =>
      category.categoryName?.toLowerCase().includes(filterValue.toLowerCase())
    )
    .slice((page - 1) * categoriesPerPage, page * categoriesPerPage);

  const totalPages = Math.ceil(categories.length / categoriesPerPage);
  const pageCount = Math.min(totalPages, 100);

  // Define columns including the Add Item button
  const columns: GridColDef[] = [
    { field: 'categoryName', headerName: 'Name', flex: 1 },
    {
      field: 'addItem',
      headerName: 'Action',
      flex: 1,
      align: 'right', // Aligns the content to the right
      headerAlign: 'right', // Aligns the header to the right
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <button
            onClick={() => handleAddItemClick(params.row.categoryId)}
            className="btn btn-success"
            style={{ padding: '5px 10px', minWidth: '100px' }}
          >
            Add Item
          </button>
        </div>
      ),
    },
  ];
  

  const handlePageChange = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleCategoriesPerPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoriesPerPage(Number(event.target.value));
    setPage(1);
  };

  const openAddCategoryPopup = () => {
    setShowAddCategoryPopup(true);
  };

  const closeAddCategoryPopup = () => {
    setShowAddCategoryPopup(false);
  };

  // Handle Add Item button click
  const handleAddItemClick = (categoryId: number) => {
    setSelectedCategoryId(categoryId);
    window.location.href = `/Menue?category=${categoryId}`;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>Categories List</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="pagination-controls">
            <span className="pagination-label">Show</span>
            <select
              value={categoriesPerPage}
              onChange={handleCategoriesPerPageChange}
              className="pagination-select"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <span className="pagination-label">Pages</span>
          </div>
        </div>

        <div className="search-container">
          <input
            type="text"
            placeholder="Search categories by name..."
            value={filterValue}
            onChange={handleFilterChange}
            className="search-input enhanced-search"
          />
          <span className="search-icon">&#128269;</span>
          {filterValue && (
            <button className="clear-button" onClick={() => setFilterValue('')}>
              &#10006;
            </button>
          )}
        </div>
      </div>

      <button onClick={openAddCategoryPopup} className="btn btn-primary">
        Add New Category +
      </button>

      <View backgroundColor="var(--amplify-colors-white)" borderRadius="6px" maxWidth="100%" padding="1rem" minHeight="80vh">
        <h2 style={{ margin: '20px 0' }}>Categories</h2>
        <div className="data-grid-container">
          <div className="data-grid">
            <DataGrid rows={filteredCategories} columns={columns} hideFooter />
          </div>
          <div className="pagination">
            <Pagination
              count={pageCount}
              page={page}
              onChange={handlePageChange}
              siblingCount={1}
              boundaryCount={1}
            />
          </div>
        </div>
      </View>

      {showAddCategoryPopup && (
        <AddCategoryPopup
          closePopup={closeAddCategoryPopup}
          fetchCategories={fetchCategoryData}
        />
      )}
    </div>
  );
};

export default CategoryPage;
