import React, { useState } from 'react';
import { addCategory } from '../../Common/SharedApis/Category';
import './category.css';

interface AddCategoryPopupProps {
  closePopup: () => void;
  fetchCategories: () => void;
}

const AddCategoryPopup: React.FC<AddCategoryPopupProps> = ({ closePopup, fetchCategories }) => {
  const [categoryName, setCategoryName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const handleSubmit = async () => {
    if (!categoryName.trim()) {
      setError('Please enter a valid category name.');
      return;
    }

    setLoading(true);
    setError('');
    try {
      await addCategory(categoryName, Number(sessionStorage.getItem('selectedBranchId')));
      fetchCategories(); // Refresh the category list
      closePopup(); // Close the popup
    } catch (err) {
      setError('Unable to add category. Please try again later.');
      console.error('Error adding category:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="category-popup-overlay">
      <div className="category-popup">
        <h3 className="popup-title">Add New Category</h3>
        <p className="popup-description">
          Enter the name of the category you wish to add. Ensure that it is unique and meaningful.
        </p>
        <input
          type="text"
          placeholder="Enter category name"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          className="popup-input"
        />
        {error && <p className="error-message">{error}</p>}
        <div className="popup-actions">
        
          <button onClick={closePopup} className="popup-close-button">
            Cancel
          </button>

          <button onClick={handleSubmit} disabled={loading} className="popup-submit-button">
            {loading ? 'Processing...' : 'Add Category'}
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default AddCategoryPopup;
