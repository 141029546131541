import React, { useEffect, useState } from "react";
import {
  View,
  Grid,
  Flex,
  Card,
  Placeholder,
  useTheme,
} from "@aws-amplify/ui-react";
import { MdRemoveRedEye, MdWeb, MdPermIdentity } from "react-icons/md";

import MiniStatistics from "./MiniStatistics";
import TrafficSources from "./TrafficSources";
import SalesSummary from "./SalesSummary";
import TrafficSummary from "./TrafficSummary";
import CustomersSummary from "./CustomersSummary";
import DasboardTiles from './DashboardTiles';

import "./Dashboard.css";
import { fetchOrderDashboard } from "../../Common/SharedApis/Order";
import DashboardTiles from "./DashboardTiles";


const Dashboard = () => {
  const [newOrders, setNewOrders] = useState<number | null>(null);
  const [canceledOrders, setCanceledOrders] = useState<number | null>(null);
 
  const { tokens } = useTheme();

  const [dashboardTilesData, setDashboardTilesData] = useState<IDashboardOrderTiles[]>([]);
  // Fetch user and restaurant details from localStorage/sessionStorage
  const userName = localStorage.getItem('userName'); // Get user name from localStorage
  const restaurantData = JSON.parse(localStorage.getItem('restaurantData') || '{}');
  const restaurantName = restaurantData?.store_Name || 'Unknown Restaurant'; // Default to 'Unknown Restaurant' if not found


  useEffect(()=>{
      const FetchOrdersData= async () =>{
        const result=await fetchOrderDashboard();
        const getDashboardTilesData = () => {
          const tilesData: IDashboardOrderTiles[] = [
            { title: "Total Orders",
              count: result.totalOrders.count,
              formattedPercentageChange: result.totalOrders.formattedPercentageChange,
              arrowDirection: result.totalOrders.arrowDirection.toLowerCase(),
              icon: '../../../Icon_Order.png'
            },
            {
              title: "Total Delivered",
              count: result.deliveredOrders.count,
              formattedPercentageChange: result.deliveredOrders.formattedPercentageChange,
              arrowDirection: result.deliveredOrders.arrowDirection.toLowerCase(),
              icon: '../../../Icon_Delivered.png' // Ensure this path is correct
            },
            {
              title: "Total Canceled",
              count: result.canceledOrders.count,
              formattedPercentageChange: result.canceledOrders.formattedPercentageChange,
              arrowDirection: result.canceledOrders.arrowDirection.toLowerCase(),
              icon: '../../../Icon_Cancelled.png'
            },
            {
              title: "Total Revenue",
              count: `$${result.totalRevenue.count.toFixed(2)}`, // Formatting the revenue
              formattedPercentageChange: result.totalRevenue.formattedPercentageChange,
              arrowDirection: result.totalRevenue.arrowDirection.toLowerCase(),
              icon: '../../../Icon_Revenue.png'
            }

           ];
          setDashboardTilesData(tilesData);
        };
    
        getDashboardTilesData();
  };
  FetchOrdersData();
  },[]);


  return (
    <>

      <div>
        <h2>Dashboard</h2>
        {/* Display user name and restaurant name */}
        <span>Hi, {userName || 'Guest'}! Welcome back to SumBite Admin!</span>
        <br />
        <span>Managing: <b> {restaurantName}</b></span>
      </div>
      <br></br>

      <div className="tiles">
      {dashboardTilesData.map((tile, index) => (
        <DashboardTiles
          key={index}
          title={tile.title}
          count={tile.count}
          formattedPercentageChange={tile.formattedPercentageChange}
          arrowDirection={tile.arrowDirection}
          icon={tile.icon}
        />
      ))}
</div>

      <View borderRadius="6px" maxWidth="100%" padding="0rem" minHeight="100vh">
    
      </View>
    </>
  );
};
export default Dashboard;
