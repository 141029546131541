import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const BranchGuard = () => {
  const selectedBranchId = sessionStorage.getItem("selectedBranchId");

  if (!selectedBranchId) {
    // Redirect to the All Branches page if no branch is selected
    return <Navigate to="/myAllBranches" replace />;
  }

  return <Outlet />;
};

export default BranchGuard;
