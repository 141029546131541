import React, { useState } from 'react';
import './MainPOS.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileEdit, faTrash, faEllipsisVertical, faMinusCircle, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';

const OrderList = () => {
  const [products, setProducts] = useState([
    { id: 1, name: 'Red Nike Laser', price: 2000, qty: 4, img: 'pos-product-16.png', code: 'PT0005' },
    { id: 2, name: 'Iphone 14', price: 3000, qty: 3, img: 'pos-product-17.png', code: 'PT0235' },
    { id: 3, name: 'Red Nike Laser', price: 2000, qty: 1, img: 'pos-product-16.png', code: 'PT0005' },
  ]);

  const [isPaymentVisible, setPaymentVisible] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false); // State for edit modal visibility
  const [isClearConfirmOpen, setClearConfirmOpen] = useState(false); // State for clear confirmation modal visibility

  const incrementQty = (id) => {
    setProducts(
      products.map(product =>
        product.id === id ? { ...product, qty: product.qty + 1 } : product
      )
    );
  };

  const decrementQty = (id) => {
    setProducts(
      products.map(product =>
        product.id === id && product.qty > 1 ? { ...product, qty: product.qty - 1 } : product
      )
    );
  };

  const handlePaymentToggle = () => {
    setPaymentVisible(!isPaymentVisible);
  };

  const handleModalToggle = () => {
    setModalOpen(!isModalOpen); // Toggle edit modal visibility
  };

  const handleClearConfirmToggle = () => {
    setClearConfirmOpen(!isClearConfirmOpen); // Toggle clear confirmation modal visibility
  };

  const handleClearAll = () => {
    setProducts([]); // Clear all products
    handleClearConfirmToggle(); // Close confirmation modal
  };

  return (
    <div className="col-md-12 col-lg-4 ps-0 sticky-aside">
      <aside className="product-order-list ">
        <div className="head d-flex align-items-center justify-content-between w-100">
          <div>
            <h5>Order List</h5>
            <span>Transaction ID : #65565</span>
          </div>
          <div>
            <a className="confirm-text" href="javascript:void(0);" onClick={handleClearConfirmToggle}>
              <FontAwesomeIcon icon={faTrash} size="2x" className="feather-16" />
            </a>
            <a href="javascript:void(0);" className="text-default">
              <FontAwesomeIcon icon={faEllipsisVertical} size="2x" className="feather-16" />
            </a>
          </div>
        </div>

        <div className="product-added block-section">
          <div className="head-text d-flex align-items-center justify-content-between">
            <h6 className="d-flex align-items-center mb-0">Product Added<span className="count">{products.length}</span></h6>
            <a href="javascript:void(0);" className="d-flex align-items-center text-danger" onClick={handleClearConfirmToggle}>
              <span className="me-1"> <FontAwesomeIcon icon={faXmark} size="2x" className="feather-16" /></span>Clear all
            </a>
          </div>

          <div className="product-wrap">
            {products.map(product => (
              <div key={product.id} className="product-list d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center product-info" data-bs-toggle="modal" data-bs-target="#products">
                  <a href="javascript:void(0);" className="img-bg">
                    <img src={`https://dreamspos.dreamstechnologies.com/html/template/assets/img/products/${product.img}`} alt="Products" />
                  </a>
                  <div className="info">
                    <span>{product.code}</span>
                    <h6><a href="javascript:void(0);">{product.name}</a></h6>
                    <p>${product.price}</p>
                  </div>
                </div>

                <div className="qty-item text-center">
                  <a href="javascript:void(0);" className="dec d-flex justify-content-center align-items-center" onClick={() => decrementQty(product.id)}>
                    <FontAwesomeIcon icon={faMinusCircle} size="2x" className="feather-16" />
                  </a>
                  <input type="text" className="form-control text-center" name="qty" value={product.qty} readOnly />
                  <a href="javascript:void(0);" className="inc d-flex justify-content-center align-items-center" onClick={() => incrementQty(product.id)}>
                    <FontAwesomeIcon icon={faPlusCircle} size="2x" className="feather-16" />
                  </a>
                </div>

                <div className="d-flex align-items-center action">
                  <a className="btn-icon edit-icon me-2" href="javascript:void(0);" onClick={handleModalToggle}>
                    <FontAwesomeIcon icon={faFileEdit} size="2x" className="feather-16" />
                  </a>
                  <a className="btn-icon delete-icon confirm-text" href="javascript:void(0);">
                    <FontAwesomeIcon icon={faTrash} size="2x" className="feather-16" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="block-section">
          <div className="order-total">
            <table className="table table-responsive table-borderless">
              <tbody>
                <tr>
                  <td>Tax (GST 5%)</td>
                  <td className="text-end">$40.21</td>
                </tr>
                <tr>
                  <td>Sub Total</td>
                  <td className="text-end">${products.reduce((acc, product) => acc + product.price * product.qty, 0).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="d-grid btn-block">
          <a className="btn btn-secondary paymentbtn" href="javascript:void(0);" onClick={handlePaymentToggle}>
            Continue To Payment
          </a>
        </div>
      </aside>

      {isPaymentVisible && (
        <div id="payment-section" className={`payment-section ${isPaymentVisible ? 'slide-in' : 'slide-out'}`}>
          <div className="payment-content">
            <div className="block-section payment-method">
              <h6>Payment Method</h6>
              <div className="row d-flex align-items-center justify-content-center methods">
                <div className="col-md-6 col-lg-4 item">
                  <div className="default-cover">
                    <a href="javascript:void(0);">
                      <img src="https://dreamspos.dreamstechnologies.com/html/template/assets/img/icons/cash-pay.svg" alt="Payment Method" />
                      <span>Cash</span>
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 item">
                  <div className="default-cover">
                    <a href="javascript:void(0);">
                      <img src="https://dreamspos.dreamstechnologies.com/html/template/assets/img/icons/credit-card.svg" alt="Payment Method" />
                      <span>Debit Card</span>
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 item">
                  <div className="default-cover">
                    <a href="javascript:void(0);">
                      <img src="https://dreamspos.dreamstechnologies.com/html/template/assets/img/icons/qr-scan.svg" alt="Payment Method" />
                      <span>Scan</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <h5>Payment Details</h5>
            <p>Enter your payment information below:</p>
            <input type="text" className="form-control" placeholder="Card Number" />
            <input type="text" className="form-control" placeholder="Expiry Date" />
            <input type="text" className="form-control" placeholder="CVV" />
            <button className="btn btn-primary mt-3">Pay Now</button>
            <button className="btn btn-danger mt-3" onClick={handlePaymentToggle}>Cancel</button>
          </div>
        </div>
      )}

      {/* Edit Product Modal */}
      {/* Edit Product Modal */}
      {isModalOpen && (
        <div className="modal fade modal-default pos-modal show" style={{ display: 'block' }} id="edit-product" aria-labelledby="edit-product">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header p-3">
                <h5 className="modal-title">Edit Product</h5>
                <button type="button" className="btn-close" onClick={handleModalToggle} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {/* Include form or fields to edit the product details */}
                <form>
                  <div className="mb-3">
                    <label htmlFor="productName" className="form-label">Product Name</label>
                    <input type="text" className="form-control" id="productName" placeholder="Enter product name" />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="productPrice" className="form-label">Product Price</label>
                    <input type="number" className="form-control" id="productPrice" placeholder="Enter product price" />
                  </div>
                  {/* Add other fields as needed */}
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleModalToggle}>Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Clear All Confirmation Modal */}
      {isClearConfirmOpen && (
       <div className="modal modal-default pos-modal show" aria-modal="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
       <div className="modal-dialog modal-dialog-centered">
         <div className="modal-content">
           <div className="modal-header">
             <h5 className="modal-title">Confirmation</h5>
             <button type="button" className="btn-close" onClick={handleClearConfirmToggle} aria-label="Close"></button>
           </div>
           <div className="modal-body">
             <p>Are you sure you want to delete all products?</p>
           </div>
           <div className="modal-footer">
             <button type="button" className="btn btn-secondary" onClick={handleClearConfirmToggle}>Cancel</button>
             <button type="button" className="btn btn-danger" onClick={handleClearAll}>Clear All</button>
           </div>
         </div>
       </div>
     </div>
     
     
      )}
      
    </div>
  );
};

export default OrderList;
