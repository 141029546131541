import React, { useState } from "react";
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FaEnvelope, FaPhone, FaBuilding, FaQuestionCircle, FaPen } from 'react-icons/fa';
import { View } from "@aws-amplify/ui-react";
import Pagination from "@mui/material/Pagination";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

interface Customer {
  id: number;
  firstName: string; 
  lastName: string;
  email: string;
  lastOrderPrice: null;
  totalSpent: number;
}

interface CustomerListProps {
  customers: Customer[];
  isLoading: boolean;
  totalPages: number;
  pageSize: number;
  handlePageChange: (newPage: number) => void;
}

const CustomerList: React.FC<CustomerListProps> = ({
  customers,
  isLoading,
  totalPages,
  pageSize,
  handlePageChange,
}) => {


  const [page, setPage] = useState(1);
  const navigate = useNavigate();


  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "firstName", headerName: "First Name", flex: 2 },
    { field: "lastName", headerName: "Last Name", flex: 2 },
    { field: "email", headerName: "Email Address", flex: 2 },
    { field: "lastOrderPrice", headerName: "Last Order Price", flex: 2 },
    { field: "totalSpent", headerName: "Total Spent", flex: 2 },
    {
      field: "view",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <button
          className="view-button"
          onClick={() => handleViewClick(params.row.id)}
        >
          View
        </button>
      ),
    },
  ];

  const handleViewClick = (id: 1) => {
    console.log("Selected Order ID:", 1); // Log the order_id
    navigate(`/customerDetails`); // Pass order_id in the URL
  };

  
  const handleChangePage = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
    handlePageChange(newPage);
  };

  // Pagination calculations
  const totalItems = customers.length;
  const startIndex = (page - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalItems);
  const displayedRows = customers.slice(startIndex, endIndex);
  const itemsShown = endIndex - startIndex;
  const pageCount = Math.ceil(totalItems / pageSize);

  return (
    <View
      borderRadius="6px"
      maxWidth="100%"
      padding="1rem"
      minHeight="80vh"
    >
      <br />
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div>
            <DataGrid
              rows={displayedRows}
              columns={columns}
              pagination
              hideFooter={true}
            />
          </div>
          <div className="pagination-container">
            <div className="pagination-info">
              {`${itemsShown} of ${totalItems} shown`}
            </div>
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChangePage}
              siblingCount={1}
              boundaryCount={1}
            />
          </div>
        </div>
      )}
    </View>
  );
};


export default CustomerList;
