import React, { useState } from "react";
import { Button, TextField, Alert } from "@aws-amplify/ui-react";
import { Link, useNavigate } from "react-router-dom";
import "./LoginPage.css";
import { login } from "../../../Common/SharedApis/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Importing icons

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  // Loading state for buttons
  const [isLoadingSignIn, setIsLoadingSignIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [values, setValues] = useState({
    email: "",
    Password: "",
  });
  const [errorMessage, setErrorMessage] = useState(""); // Use errorMessage for clarity
  const [rememberMe, setRememberMe] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoadingSignIn(true); // Set loading to true when submitting
    setErrorMessage(""); // Clear previous error message

    const dataToSend = {
      email: values.email,
      password: values.Password,
    };

    try {
      const responseData = await login(dataToSend);
      const userId = responseData.userId; // Change this according to your API response structure

      if (rememberMe) {
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.setItem("rememberMe", "false");
      }

      // Save the user ID in local storage
      localStorage.setItem("userId", userId);

      navigate("/Myallresturent");
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("")) {
          setErrorMessage("Invalid email or password. Please try again.");
        } else {
          setErrorMessage(error.message);
        }
      } else {
        setErrorMessage("An unknown error occurred. Please try again.");
      }
    } finally {
      setIsLoadingSignIn(false); // Set loading to false after the request completes
    }
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const GoToNewRestaurant = async () => {
    setIsLoadingRegister(true); // Set loading for the register button
    try {
      // Add any logic you want to perform when this button is clicked
      navigate("/create-resturant");
    } finally {
      setIsLoadingRegister(false); // Reset loading state after navigation
    }
  };

  return (
    <div className="main-page">
      {/* Left Section */}
      <div className="left-section">
        <img
          src="/sumbite_white_logo.png"
          alt="SumBite Logo"
          className="logo"
        />
        <p>Stay In Control</p>
        <p>
          Track orders in real-time from any device, manage tables, and
          streamline your restaurant operations effortlessly.
        </p>
        <div className="social-icons">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      {/* Right Section */}
      <div className="right-section">
        <form className="login-form" onSubmit={handleSubmit}>
          {/* Display error message as an Alert */}
          {errorMessage && (
            <Alert variation="error" isDismissible={true}>
              {errorMessage}
            </Alert>
          )}
          <h2>Welcome Back!</h2>
          <p className="subtitle">Log in to access your account</p>

          <TextField
            value={values.email}
            onChange={handleInputChange}
            name="email"
            label="Email Address"
            type="email"
            width="100%"
            required
            className="text-field"
            placeholder="Enter your email"
          />
          <div style={{ position: "relative", width: "100%" }}>
            <TextField
              value={values.Password}
              onChange={handleInputChange}
              name="Password"
              label="Password"
              type={showPassword ? "text" : "password"}
              width="100%"
              required
              className="text-field"
              placeholder="Enter your Password"
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "10px",
                top: "58%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </span>
          </div>

          <div className="remember-me">
            <input
              type="checkbox"
              id="remember"
              checked={rememberMe}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="remember">Remember me</label>
          </div>

          <Button
            className="button-style"
            type="submit"
            isLoading={isLoadingSignIn}
          >
            Sign In
          </Button>

          <div className="links-container">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>

          <hr className="divider" />

          <div className="new-account-section">
            <p className="account-question">
              Are you a restaurant or mart owner?
            </p>
            <Button
              onClick={GoToNewRestaurant}
              className="button-style secondary"
              type="button"
              isLoading={isLoadingRegister}
            >
              Create an Account for Free
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
