// AudioContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';

interface AudioContextType {
    playNotification: () => void;
    playMessage: () => void;
}

const AudioContext = createContext<AudioContextType | undefined>(undefined);

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
    const [userInteracted, setUserInteracted] = useState(false);

    useEffect(() => {
        const handleUserInteraction = () => {
            setUserInteracted(true);
            document.removeEventListener('click', handleUserInteraction);
            document.removeEventListener('keydown', handleUserInteraction);
        };

        document.addEventListener('click', handleUserInteraction);
        document.addEventListener('keydown', handleUserInteraction);

        return () => {
            document.removeEventListener('click', handleUserInteraction);
            document.removeEventListener('keydown', handleUserInteraction);
        };
    }, []);

    const playNotification = () => {        
        playAudio('/Notifications/Notification.wav');     
    };

    const playMessage = () => {
       playAudio('/Notifications/Message.wav');       
    };

    const playAudio = (src: string) => {
        if (currentAudio) {
            currentAudio.pause();
        }

        const audio = new Audio(src);
        setCurrentAudio(audio);
        audio.play().catch((err) => {
            console.error("Failed to play audio:", err);
        });

        // Clean up when the audio ends
        audio.onended = () => {
            setCurrentAudio(null);
        };
    };

    const notifyUserToInteract = () => {
        console.log("Please click on the page to enable sound playback.");
        // You can also use a toast notification or any UI feedback here.
    };

    return (
        <AudioContext.Provider value={{ playNotification, playMessage }}>
            {children}
        </AudioContext.Provider>
    );
};

export const useAudio = () => {
    const context = useContext(AudioContext);
    if (context === undefined) {
        throw new Error('useAudio must be used within an AudioProvider');
    }
    return context;
};
