import React, { useState, useEffect } from "react";
import { TextField, Button, Alert } from "@aws-amplify/ui-react"; // Use Alert for error/success messages
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { resetPassword, forgotPassword } from "../../../Common/SharedApis/Auth";
import { faFacebookF, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ResetPasswordPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>(location.state?.email || "");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Handle errors separately
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [timer, setTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "code") setCode(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
  };

  // Handle Change Password button click
  const handleChangePasswordClick = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    setErrorMessage(""); // Clear previous errors
    setMessage(""); // Clear previous messages

    if (code && newPassword === confirmPassword) {
      try {
        const success = await resetPassword(email, code, newPassword);
        if (success) {
          setMessage("Password updated successfully!");
          setTimeout(() => {
            navigate("/"); // Redirect after 3 seconds
          }, 3000);
        } else {
          setErrorMessage("Error updating the password.");
        }
      } catch (error) {
        setErrorMessage(
          `Error updating password: ${
            error instanceof Error
              ? error.message
              : "An unknown error occurred."
          }`
        );
      }
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match. Please try again.");
    } else {
      setErrorMessage("Please enter the code.");
    }

    setIsLoading(false); // Stop loading
  };

  // Handle Resend Code button click
  const handleResendCode = async () => {
    setMessage("A new code has been sent to your email.");
    setIsResendDisabled(true);
    setTimer(60);
    try {
      await forgotPassword(email);
    } catch (error) {
      setErrorMessage("Error sending the code. Please try again.");
    }
  };

  // Countdown for resend button
  useEffect(() => {
    if (isResendDisabled && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setIsResendDisabled(false);
    }
  }, [timer, isResendDisabled]);

  return (
    <div className="main-page">
      {/* Left Section */}
      <div className="left-section">
        <h1>SumBite</h1>
        <p>Stay In Control</p>
        <p>
          Track orders in real-time from any device, manage tables, and
          streamline your restaurant operations effortlessly.
        </p>
        <div className="social-icons">
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a
            href="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      {/* Right Section */}
      <div className="right-section">
        <form className="login-form" onSubmit={handleChangePasswordClick}>
          <h2>Reset Password</h2>

          {/* Display error message */}
          {errorMessage && (
            <Alert variation="error" isDismissible={true}>
              {errorMessage}
            </Alert>
          )}

          {/* Display success message */}
          {message && (
            <Alert variation="success" isDismissible={true}>
              {message}
            </Alert>
          )}

          {/* Hidden Email Field */}
          <input type="hidden" name="email" value={email} />
          <TextField
            name="code"
            label="Enter Code"
            type="text"
            value={code}
            onChange={handleInputChange}
            required
            width="100%"
            className="text-field"
          />
          <div>
            {/* New Password Field */}
            <div style={{ position: "relative", width: "100%" }}>
              <TextField
                name="newPassword"
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={handleInputChange}
                required
                className="text-field"
              />
              <span
                onClick={() => setShowNewPassword(!showNewPassword)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "58%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showNewPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </span>
            </div>

            {/* Confirm Password Field */}
            <div
              style={{ position: "relative", width: "100%", marginTop: "10px" }}
            >
              <TextField
                name="confirmPassword"
                label="Re-enter New Password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleInputChange}
                required
                className="text-field"
              />
              <span
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "58%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showConfirmPassword ? (
                  <AiOutlineEyeInvisible />
                ) : (
                  <AiOutlineEye />
                )}
              </span>
            </div>
          </div>

          {/* Button with loading spinner */}
          <Button className="button-style" type="submit" isLoading={isLoading}>
            {isLoading ? "Changing..." : "Change Password"}
          </Button>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Button
              className="resend-button"
              onClick={handleResendCode}
              disabled={isResendDisabled}
            >
              Resend Code {isResendDisabled && `(${timer}s)`}
            </Button>
            <span>
              <Link to="/login">Back To Login</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
