import React, { useState, useEffect } from 'react';
import OrderType from './Ordertype';
import Categories from './Categories';
import { Modal, Button, Card, Form } from 'react-bootstrap';
import { fetchItemByCategoryId } from '../../Common/SharedApis/Item';

const ProductList = () => {
  const [filteredCategory, setFilteredCategory] = useState<string>('all');
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedVariant, setSelectedVariant] = useState<string>('');

  const fetchProducts = async (categoryId: string) => {
    setLoading(true);
    const restaurantId = 1;
    try {
      const fetchedItems = await fetchItemByCategoryId(restaurantId.toString(), categoryId);
      setProducts(fetchedItems.items);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filteredCategory === 'all') {
      setProducts([]);
    } else {
      fetchProducts(filteredCategory);
    }
  }, [filteredCategory]);

  const handleProductClick = (product: any) => {
    setSelectedProduct(product);
    setSelectedVariant('');
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleVariantChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setSelectedVariant(event.target.value);
  };

  return (
    <div className="col-md-12 col-lg-8">
      <div className="pos-categories tabs_wrapper">
        <Categories onCategoryChange={(category) => setFilteredCategory(category)} />

        <div className="pos-products">
          <OrderType />
          <div className="tabs_container">
            <div className="tab_content mt-5 active" data-tab="all">
              <div className="row" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                {loading ? (
                  <p>Loading products...</p>
                ) : (
                  products.map((product) => (
                    <div
                      key={product.itemId}
                      className="col-sm-2 col-md-6 col-lg-4 col-xl-3"
                      style={{
                        marginBottom: '30px', // Space between cards
                        display: 'flex',
                        width:'30%',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Card
                        className="rounded shadow product-info default-cover"
                        style={{
                          width: '290px', // Fixed width for card
                          height: '350px', // Fixed height for card
                          borderRadius: '12px',
                          boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)', // Soft shadow effect
                          textAlign: 'center',
                          padding: '20px'
                        }}
                        onClick={() => handleProductClick(product)}
                      >
                        <div
                          className="card-img-container"
                          style={{
                            height: '150px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '20px' // Space between image and text
                          }}
                        >
                          <div className="img-bg">
                            <Card.Img
                              variant="top"
                              src={product.largeImage || product.smallImage}
                              alt={product.itemName}
                              className="rounded-circle card-img"
                              style={{
                                width: '120px',
                                height: '120px',
                                objectFit: 'cover',
                                borderRadius: '50%',
                                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)' // Shadow around image
                              }}
                            />
                          </div>
                        </div>
                        <Card.Body>
                          <h6 className="product-name" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                            <a href="javascript:void(0);">{product.itemName}</a>
                          </h6>
                          <h6 className="cat-name" style={{ color: '#b0b0b0', marginBottom: '10px' }}>
                            {product.itemName}
                          </h6>
                          <div className="price" style={{ color: '#9b59b6', fontSize: '1.2rem' }}>
                            ${product.itemPrice}
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedProduct && (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedProduct.itemName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={selectedProduct.largeImage || selectedProduct.smallImage}
              alt={selectedProduct.itemName}
              className="img-fluid mb-3"
              style={{ width: '150px', height: '150px', objectFit: 'cover' }}
            />
            <p>Price: ${selectedProduct.itemPrice}</p>
            <p>Category: {selectedProduct.category || 'N/A'}</p>

            {selectedProduct.variants.length > 0 && (
              <Form.Group controlId="variantSelect">
                <Form.Label>Select a variant</Form.Label>
                <Form.Control as="select" value={selectedVariant} onChange={handleVariantChange}>
                  <option value="">--Select--</option>
                  {selectedProduct.variants.map((variant: string, index: number) => (
                    <option key={index} value={variant}>
                      {variant}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                console.log(`Selected Variant: ${selectedVariant}`);
                handleClose();
              }}
            >
              Add to Cart
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ProductList;
