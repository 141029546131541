import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './Map.css';

mapboxgl.accessToken = "pk.eyJ1IjoidGhlbWV0YXN1bSIsImEiOiJjbTFiczFtMmQwZHdnMmxzNmRsZ292d3hiIn0.XvXHgjczGqyH7DPS8aeN_A";

const Map: React.FC = () => {
  const mapContainerRef = useRef<HTMLDivElement>(null); 

  useEffect(() => {
    if (mapContainerRef.current) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current, 
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-74.5, 40], 
        zoom: 9, 
      });

      const markerDiv = document.createElement('div');
      markerDiv.className = 'custom-marker'; // We'll define this in CSS
      markerDiv.innerHTML = `<div style="font-size:24px; color: red;"><i class="fas fa-map-marker-alt"></i></div>`;

      // Add marker to the map
      new mapboxgl.Marker(markerDiv)
        .setLngLat([-74.5, 40]) 
        .addTo(map);

      return () => {
        map.remove();
      };
    }
  }, []); 

  return (
    <>
      <div
        ref={mapContainerRef}
        className="map-container"
        style={{ width: '100%', height: '400px' }}
      />
    </>
  );
};

export default Map;


