import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fetchcategory } from '../../Common/SharedApis/Category'; // Import the fetchcategory function
import { fetchItemByCategoryId } from '../../Common/SharedApis/Item'; // Import the fetchItemByCategoryId function
import "./Categories.css";

interface CategoriesProps {
  onCategoryChange: (category: string) => void;
}

const Categories: React.FC<CategoriesProps> = ({ onCategoryChange }) => {
  const [activeCategory, setActiveCategory] = useState<string>('all');
  const [categories, setCategories] = useState<{ id: string; name: string; totalItems: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state
  const [items, setItems] = useState<any[]>([]); // State for storing fetched items

  // Fetch categories on component mount
  useEffect(() => {
    const loadCategories = async () => {
      setLoading(true); // Set loading to true before fetching
      const restaurantId = 1; // Hardcode restaurant ID to 1
      const fetchedCategories = await fetchcategory(restaurantId);
      setCategories(fetchedCategories.map((category: any) => ({
        id: category.categoryId.toString(), // Use categoryId as id
        name: category.categoryName, // Use categoryName as name
        totalItems: category.totalItems, // Use totalItems directly from API
      })));
      setLoading(false); // Set loading to false after fetching
    };

    loadCategories();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleCategoryClick = async (id: string) => {
    setActiveCategory(id);
    onCategoryChange(id);
    const restaurantId = 1; // Use the same hardcoded restaurant ID

    try {
      const fetchedItems = await fetchItemByCategoryId(restaurantId.toString(), id);
      setItems(fetchedItems); // Set fetched items to state
      console.log('Fetched Items:', fetchedItems); // Log fetched items for debugging
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  return (
    <ul className="tabs owl-carousel pos-category">
      <Slider {...settings}>
        {loading ? (
          // Display shimmer loaders while categories are being fetched
          Array.from({ length: 6 }).map((_, index) => (
            <div className="item" key={index}>
              <div className="shimmer shimmer-item"></div> {/* Shimmer placeholder */}
            </div>
          ))
        ) : (
          categories.map((category) => (
            <div className="item" key={category.id}>
              <li
                id={category.id}
                className={activeCategory === category.id ? 'active' : ''}
                onClick={() => handleCategoryClick(category.id)}
              >
                <h6>
                  <a href="javascript:void(0);">{category.name}</a>
                </h6>
                <span>{category.totalItems} Items</span>
              </li>
            </div>
          ))
        )}
      </Slider>
    </ul>

    
  );
};

export default Categories;
