import React, { useState } from 'react';

const MusicPage: React.FC = () => {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTrack, setCurrentTrack] = useState<string | null>(null);

  // Function to handle playing/pausing music
  const toggleMusic = (audioFile: string) => {
    // If the same track is clicked, pause it
    if (currentTrack === audioFile) {
      if (currentAudio) {
        currentAudio.paused ? currentAudio.play() : currentAudio.pause();
      }
      setIsPlaying(!isPlaying);
      return;
    }

    // Stop the current audio if playing
    if (currentAudio) {
      currentAudio.pause();
    }

    // Create a new Audio instance and play it
    const audio = new Audio(audioFile);
    setCurrentAudio(audio);
    setCurrentTrack(audioFile);
    setIsPlaying(true);
    audio.play();

    // When the audio ends, reset the state
    audio.onended = () => {
      setCurrentAudio(null);
      setCurrentTrack(null);
      setIsPlaying(false);
    };
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Music Player</h1>
      <div style={{ marginBottom: '20px' }}>
        <button onClick={() => toggleMusic('/Notifications/Message.wav')}>
          {isPlaying && currentTrack === '/Notifications/notification.mp3' ? 'Pause Notification Sound' : 'Play Notification Sound'}
        </button>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <button onClick={() => toggleMusic('/Notifications/Notification.wav')}>
          {isPlaying && currentTrack === '/Notifications/message.mp3' ? 'Pause Message Sound' : 'Play Message Sound'}
        </button>
      </div>
    </div>
  );
};

export default MusicPage;
