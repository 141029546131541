import React from 'react';
import { Container, Row, Col, InputGroup, FormControl, Button, ListGroup, Image, Badge } from 'react-bootstrap';
import './Chat.css'; 
import ChatLeftSide from './ChatLeftSide';
import ChatRightSide from './ChatRightSide';

const ChatSidebar: React.FC = () => {
  return (
    <>
      <ChatLeftSide />
      <ChatRightSide />
    </>
   

  );
};

export default ChatSidebar;
