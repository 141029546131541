
import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { FaEnvelope, FaPhone, FaBuilding, FaQuestionCircle, FaPen } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MainDashboard = () => {
  const handleButtonClick = () => {
    toast.success("Reconnected to the server.");
  };

  return (
    <div>
      <h2>Customer List</h2>
      
      {/* Button that triggers toast */}
      <Button variant="primary" onClick={handleButtonClick}>
        Click me to show toast
      </Button>

      {/* Toast container */}
      <ToastContainer />
    </div>
  );
};

export default MainDashboard;
