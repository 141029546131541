import React from 'react';
import { Container, ListGroup, InputGroup, FormControl, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faSmile } from '@fortawesome/free-solid-svg-icons'; 

const ChatRightSide: React.FC = () => {
  return (
   
    <Container 
    fluid 
    style={{ 
      marginLeft: '320px',  
      padding: '20px', 
      height: '100vh',
      maxWidth: '700px',
      marginTop: '40px',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
    }}
    className="chat-right-side"
    >
    
    {/* Header (User Info) */}
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      padding: '10px 0',
      borderBottom: '1px solid #ececec',
      marginBottom: '15px',
    }}>
      <img 
        src="https://via.placeholder.com/40" 
        alt="user avatar" 
        style={{ 
          borderRadius: '50%', 
          width: '40px', 
          height: '40px', 
          marginRight: '15px'
        }} 
      />
      <div>
        <h5 style={{ marginBottom: '0', fontWeight: 'bold' }}>Roberto</h5>
        <p style={{ margin: '0', color: '#7d7d7d' }}>Online</p>
      </div>
    </div>
  
    {/* Chat Messages */}
    <div style={{
      flexGrow: 1,
      overflowY: 'auto',
      padding: '10px',
    //   backgroundColor: '#f4f7fb',
      borderRadius: '8px',
    //   boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    }}>
      <div style={{ marginBottom: '15px' }}>
        <p style={{ backgroundColor: '#ececec', padding: '10px', borderRadius: '20px', display: 'inline-block' }}>
          Hi everyone! Let’s start our discussion now about kick off meeting next week.
        </p>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <p style={{ backgroundColor: '#ececec', padding: '10px', borderRadius: '20px', display: 'inline-block' }}>
          Is everyone ok about that schedule?
        </p>
      </div>
      <div style={{ textAlign: 'right', marginBottom: '15px' }}>
        <p style={{ backgroundColor: '#d3f0fd', padding: '10px', borderRadius: '20px', display: 'inline-block' }}>
          Uhm, can I reschedule the meeting?
        </p>
        <p style={{ backgroundColor: '#d3f0fd', padding: '10px', borderRadius: '20px', display: 'inline-block' }}>
          I still have 2 tasks left that day. I’m worried I can’t attend that meeting.
        </p>
      </div>
    </div>
  
    {/* Input Field */}
    <div style={{ marginTop: '15px',
    position: 'sticky', 
    bottom: '10%', 
    backgroundColor: '#fff',
    padding: '15px 0',
     }}>
      <input 
        type="text" 
        placeholder="Write your message here..." 
        style={{ 
          width: '100%', 
          padding: '15px', 
          borderRadius: '30px', 
          border: '1px solid #ccc', 
          outline: 'none',
        }} 
      />
       
    </div>
    
  </Container>

  );
};

export default ChatRightSide;
