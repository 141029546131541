import React from 'react';
import { Container, Row, Col, Card, ListGroup, Button, Image } from 'react-bootstrap';
import { FaAws } from 'react-icons/fa'; // AWS icons
import ProfileCard from './ProfileCard';
// import profileImage from '../../../plate.png'; // Example image

const CustomerDetails: React.FC = () => {
  return (
    
    <Container fluid className="mt-4" style={{ padding: '20px' }}>
     <h3>Customer Details</h3>
     <p className='mb-4 small'>Here your Customer Detail Profile</p>
      <Row className="g-4">
        {/* Left Side - Customer Information */}
        <Col md={8} className="d-flex">
          <ProfileCard></ProfileCard>
        </Col>

        {/* Right Side - Balance Card */}
        <Col md={4} className="d-flex">
          <Card
            className="flex-fill text-center p-3"
            style={{
              backgroundColor: '#00B87C', // Correct green background
              borderRadius: '12px',
              color: 'white',
              minHeight: '180px', // Ensure height is consistent with design
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            {/* Card header with ellipsis */}
            <div className="d-flex justify-content-between align-items-start">
              <span>Your Balance</span>
              <span style={{ fontSize: '1.5rem' }}>...</span>
            </div>

            {/* Card content */}
            <div>
              <h2 style={{ fontSize: '2.5rem', marginBottom: '10px' }}>$9,425</h2>
              <p style={{ marginBottom: '10px' }}>2451 **** **** ****</p>
              <div className="d-flex justify-content-between">
                <span style={{ fontSize: '1rem' }}>Name</span>
                <span style={{ fontSize: '1rem' }}>02/21</span>
              </div>
            </div>

            {/* Card footer */}
            <div className="d-flex justify-content-between align-items-center"
              style={{
                backgroundColor: '#008E68', // Darker green for footer
                borderRadius: '0 0 12px 12px',
                padding: '10px'
              }}>
              <span>Eren Yeager</span>
              <div className="d-flex align-items-center">
                <div className="bg-light"
                  style={{
                    width: '40px',
                    height: '20px',
                    borderRadius: '20px'
                  }}></div>
                <span style={{ marginLeft: '10px', fontSize: '0.75rem' }}>Mastercard</span>
              </div>
            </div>


          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        {/* Most Ordered Food */}
        <Col md={6}>
          <Card className="p-3" style={{ backgroundColor: '#FFFFFF', borderRadius: '12px' }}>
            <Card.Header style={{ backgroundColor: 'transparent', borderBottom: 'none', padding: 0 }}>
              <h6 style={{ color: '#3F4A5F', fontWeight: 'bold' }}>Most Ordered Food</h6>
            </Card.Header>
            <Card.Body style={{ padding: '0' }}>
              <ListGroup variant="flush">
                {[...Array(4)].map((_, idx) => (
                  <ListGroup.Item key={idx} className="d-flex justify-content-between align-items-center" style={{ padding: '10px 0', border: 'none' }}>
                    <div className="d-flex align-items-center">
                      <img src="https://via.placeholder.com/50" className="me-3" width="50" />
                      <div>
                        <h6 className="mb-0" style={{ color: '#3F4A5F' }}>Medium Spicy Spaghetti Italiano</h6>
                        <p className="small text-muted mb-0">SPAGHETTI</p>
                      </div>
                    </div>
                    <div style={{ color: '#3F4A5F', fontWeight: 'bold' }}>$12.56</div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        {/* Most Liked Food */}
        <Col md={6}>
          <Card className="p-3" style={{ backgroundColor: '#FFFFFF', borderRadius: '12px' }}>
            <Card.Header style={{ backgroundColor: 'transparent', borderBottom: 'none', padding: 0 }}>
              <h6 style={{ color: '#3F4A5F', fontWeight: 'bold' }}>Most Liked Food</h6>
            </Card.Header>
            <Card.Body>
              <div className="text-center">
                <h6 style={{ color: '#3F4A5F', fontWeight: 'bold' }}>763 Likes</h6>
                <p className="small text-muted">Oct 23rd, 2020</p>
              </div>
              <div className="chart-container">
                {/* Placeholder for chart */}
                <p>[Chart goes here]</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerDetails;
