import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Dropdown, Table, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faClock , faTruck} from '@fortawesome/free-solid-svg-icons';
import "./NewOrderDetails.css";
import { getOrderDetails, getOrderTracking } from '../../Common/SharedApis/Order';
import { useParams } from 'react-router-dom';
import mapboxgl from 'mapbox-gl'; // Import Mapbox
import 'mapbox-gl/dist/mapbox-gl.css'; // Mapbox CSS

mapboxgl.accessToken = 'pk.eyJ1IjoidGhlbWV0YXN1bSIsImEiOiJjbTFiczFtMmQwZHdnMmxzNmRsZ292d3hiIn0.XvXHgjczGqyH7DPS8aeN_A'; // Replace with your Mapbox token

const NewOrderDetails = () => {

  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [trackingData, setTrackingData] = useState<any>(null); // Tracking data state
// Get the order_id from the route params
const { order_id } = useParams<{ order_id: string }>();
// Convert the order_id to a number
const orderId = Number(order_id);


  const riderId = 0; // Example riderId, you can dynamically fetch this as well
  

   // Fetch order details from the service
  
   useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const data = await getOrderDetails(orderId, riderId);
        setOrderDetails(data.ordersVM);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

  
  const fetchOrderTracking = async () => {
    try {
      const trackingData = await getOrderTracking(orderId); // Fetch tracking data
      setTrackingData(trackingData.data); // Set the tracking data
    } catch (error) {
      console.error('Error fetching order tracking:', error);
    }
  };

  fetchOrderDetails();
  fetchOrderTracking(); // Call tracking API
}, [orderId, riderId]);


 // Initialize Mapbox and plot rider and delivery location
 useEffect(() => {
  if (trackingData) {
    const map = new mapboxgl.Map({
      container: 'map', // Container ID for the map
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [trackingData.riderLocation.longitude, trackingData.riderLocation.latitude], // Rider's location
      zoom: 12
    });

    // Add a marker for the rider's current location
    new mapboxgl.Marker({ color: 'red' })
      .setLngLat([trackingData.riderLocation.longitude, trackingData.riderLocation.latitude])
      .setPopup(new mapboxgl.Popup().setText('Rider Location')) // Optional popup
      .addTo(map);

    // Add a marker for the delivery location
    new mapboxgl.Marker({ color: 'green' })
      .setLngLat([trackingData.deliveryLocation.longitude, trackingData.deliveryLocation.latitude])
      .setPopup(new mapboxgl.Popup().setText('Delivery Location')) // Optional popup
      .addTo(map);

    //direction
    // Fetch directions from the Mapbox Directions API
    const fetchRoute = async () => {
      const directionsURL = `https://api.mapbox.com/directions/v5/mapbox/driving/${trackingData.riderLocation.longitude},${trackingData.riderLocation.latitude};${trackingData.deliveryLocation.longitude},${trackingData.deliveryLocation.latitude}?geometries=geojson&access_token=${mapboxgl.accessToken}`;
      const response = await fetch(directionsURL);
      const data = await response.json();

      const route = data.routes[0].geometry;

      // Add the route to the map as a blue line
      map.on('load', () => {
        map.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: route
          }
        });

        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#007aff',
            'line-width': 4
          }
        });
      });


    // Fit both locations into view
    map.fitBounds([
      [trackingData.riderLocation.longitude, trackingData.riderLocation.latitude],
      [trackingData.deliveryLocation.longitude, trackingData.deliveryLocation.latitude]
    ], { padding: 50 });
  };
    fetchRoute();
  }
}, [trackingData]);

  return (
    <Container fluid className="p-4">
      {/* Top Section: Order Number, Cancel Button, and Delivery Dropdown */}
      <Row className="align-items-center mb-4">
        <Col>
          <h5>Order ID #{orderDetails?.order_id}</h5>
        </Col>
        <Col className="text-right d-flex justify-content-around">
          {/* Cancel Order Button */}
          <Button variant="danger" className="mr-2" style={{ width: '150px' }}>
            Cancel Order
          </Button>

          {/* On Delivery Dropdown Button */}
          <Dropdown>
            <Dropdown.Toggle variant="success" className='order-status' id="dropdown-basic" style={{ width: '150px' }}>
              On Delivery
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#">In Transit</Dropdown.Item>
              <Dropdown.Item href="#">Delivered</Dropdown.Item>
              <Dropdown.Item href="#">Delayed</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>

      <Row>
        {/* Left Side: Profile and Note Order */}
        <Col md={3}>
          {/* Profile Card */}
          <Card className="Customer-card">
            <Card.Body className="text-center">
            <img
              src={orderDetails?.shippingInformation.customerPhoto || "https://via.placeholder.com/100"} // Use the customer photo if available
              alt="Profile"
              className="rounded-circle mb-3"
            />
              <Card.Title>{orderDetails?.shippingInformation.customerFullName}</Card.Title>
              <Card.Subtitle className="text-muted">Customer</Card.Subtitle>
            </Card.Body>
          </Card>

          {/* Note Order */}
          <Card className="mb-4 note-card">
            <Card.Header>Note Order</Card.Header>
            <Card.Body>
              <Card.Text>{orderDetails?.specialInstruction}</Card.Text>
              <ListGroup variant="flush">
                <ListGroup.Item><i className="fa fa-map-marker"></i>{orderDetails?.shippingInformation.customerAddress}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>

          {/* Order Track Section */}
          <section className="order-track-section">
            <Card className="mb-4 track-card">
              <Card.Header>History</Card.Header>
              <div className="order-track">
              {orderDetails?.lstStatusHistory?.length ? ( // Check if lstStatusHistory exists and has length
                orderDetails.lstStatusHistory.map((step, index) => (
                  <div className="order-track-step" key={index}>
                    <div className="order-track-status">
                      <span className="order-track-status-dot"></span>
                      <span className="order-track-status-line"></span>
                    </div>
                    <div className="order-track-text">
                      <p className="order-track-text-stat">{step.statusName}</p>
                      <span className="order-track-text-sub">{step.statusHistoryDateTime}</span>
                    </div>
                  </div>
                ))
              ) : (
                <p>No status history available.</p> // Optional: Message if no status history
              )}
              </div>
            </Card>
          </section>
        </Col>

        {/* Right Side: Order Details */}
        <Col md={9}>
        <Card className="mb-4 order-table">
  <Card.Header>
    <Table responsive className="mb-0">
      <thead>
        <tr>
          <th>Item</th>
          <th>Qty</th>
          <th>Price</th>
          <th>Total Price</th>
        </tr>
      </thead>
    </Table>
  </Card.Header>
  <Card.Body>
    <Table responsive className="mb-0">
      <tbody>

      {orderDetails?._OrderDetails?.map((item) => (
        <tr key={item.order_details_id}>
          <td>
            <div className="item-info">
              <img
                src="https://via.placeholder.com/50"
                alt={item.item_name || "Item Image"}
                className="item-image"
              />
              {item.item_name || "Unnamed Item"} {/* Fallback for item name */}
            </div>
          </td>
          <td>{item.item_qty}x</td>
          {item.item_price ? Number(item.item_price).toFixed(1) : "N/A"}
          <td>${((item.item_price || 0) * (item.item_qty || 0)).toFixed(1) || "N/A"}</td>
        </tr>
      ))}
     
      </tbody>
    </Table>
  </Card.Body>
</Card>

<Card className="track-order">
            <Card.Body>
              <h5>Track Orders</h5>
              <div id="map" style={{ width: '100%', height: '400px' }}></div> {/* Mapbox container */}
              <div className="d-flex align-items-center mt-4">
                <FontAwesomeIcon icon={faTruck} size="2x" className="mr-3" />
                <div>
                  <strong>{trackingData?.estimatedTime}</strong> Estimated time
                </div>
              </div>
              <div>
                <h6>Delivery by</h6>
                <img src="https://via.placeholder.com/50" alt="Delivery Person" className="rounded-circle mr-3" />
                <strong>{trackingData?.riderName}</strong>
                <p>ID: 424365</p>
                <div className="contact-info">
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faPhone} />
                    <p>{trackingData?.riderContact || 'N/A'}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faClock} />
                    <p>{trackingData?.estimatedTime || 'N/A'}</p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NewOrderDetails;
