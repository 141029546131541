import React, { useState, useEffect } from "react";
import { View, Heading, ScrollView } from "@aws-amplify/ui-react";
import { fetchCustomers } from "../../Common/SharedApis/Customer";
import CustomerList from "./CustomerList";

interface Customer {
    id: number;
    firstName: string; 
    lastName: string;
    email: string;
    lastOrderPrice: null;
    totalSpent: number;
  }

const CustomerListPage = () => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]); // State to store filtered orders
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [totalPages, setTotalPages] = useState(1); // State to store totalPages
    const [itemsPerPage, setItemsPerPage] = useState(10); // State for items per page
    const [filterValue, setFilterValue] = useState(""); // State for filter value
  


    useEffect(() => {
    

 const fetchDataFromAllPages = async () => {
        const allData: Customer[] = [];
        let idCounter = 1; // Counter for generating unique IDs

        try {
          let page = 1;
          let totalFetchedPages = 1;
  
          while (page <= totalFetchedPages) {
            // Use the generic fetchOrders function
            const data = await fetchCustomers(page, itemsPerPage, searchQuery);
      
            const customersWithIds: Customer[] = data.customers.map((customer: Customer) => ({
              ...customer,
              id: idCounter++, // Increment the ID counter for uniqueness
            }));

            allData.push(...customersWithIds);

        if (page === 1) {
          totalFetchedPages = data.totalPages || 1; // Fetch totalPages from the API response
          setTotalPages(totalFetchedPages); // Update totalPages state
        }

        page++;
      }
  
      setCustomers(allData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setIsLoading(false);
    }
  };

  fetchDataFromAllPages();
}, [currentPage, searchQuery, itemsPerPage]); 




const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
          setCurrentPage(newPage);
        }
      };

      const handleSearchInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when search query changes
      };

      const handleItemsPerPageChange = (
        event: React.ChangeEvent<HTMLSelectElement>
      ) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1); // Reset to the first page when items per page changes
      };

      const handleFilterChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        setFilterValue(event.target.value);
      };



  useEffect(() => {
    // Filter customers based on the search query
    const filtered = customers.filter((customer) =>
      customer.id.toString().includes(searchQuery)
    );
    setFilteredCustomers(filtered);
  }, [searchQuery, customers]); // Filter whenever searchQuery or orders change


  return (
    <View
      borderRadius="6px"
      maxWidth="100%"
      padding="1rem"
      minHeight="80vh"
    >
      <Heading>Customers Profiles</Heading>
      <br />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="pagination-controls">
            <span className="pagination-label">Show</span>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="pagination-select">
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <span className="pagination-label">per page</span>
          </div>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search items by ID..."
            value={searchQuery}
            onChange={handleSearchInputChange}
            className="search-input enhanced-search"
          />
          <span className="search-icon">&#128269;</span>
          {searchQuery && (
            <button className="clear-buttons" onClick={() => setSearchQuery('')}>
              &#10006;
            </button>
          )}
        </div>
      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <ScrollView width="100%">
            <CustomerList
              customers={filteredCustomers}
              isLoading={false}
              pageSize={itemsPerPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages} // Use state value for totalPages
            />
          </ScrollView>
        </div>
      )}
    </View>
  );
};

export default CustomerListPage;