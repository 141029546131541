import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./SideBar.css";

const SidebarNav = ({ navs }) => {
  const [openMenu, setOpenMenu] = useState(null);

  const toggleSubMenu = (index) => {
    setOpenMenu(openMenu === index ? null : index); // Toggle submenu
  };

  return (
    <div className="sidebar-nav">
      <ul>
        {navs.map((nav, index) => (
          <li key={index} className={`menu-item ${openMenu === index ? "open" : ""}`}>
            <NavLink to={nav.to} onClick={() => nav.children && toggleSubMenu(index)}>
              {nav.icon}
              {nav.title}
              {nav.children && <span className={`dropdown-arrow ${openMenu === index ? "open" : ""}`}>▶</span>} {/* Arrow for submenus */}
            </NavLink>
            {nav.children && (
              <ul className={`submenu ${openMenu === index ? "open" : ""}`}>
                {nav.children.map((subNav, subIndex) => (
                  <li key={subIndex}>
                    <NavLink to={subNav.to}>
                      {subNav.icon && <span className="sub-item-icon">{subNav.icon}</span>}
                      {subNav.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SidebarNav;
